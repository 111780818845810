import React from "react";
import { EmailSequencerInput } from "./EmailSequencerInput";
import { DomainProviderInput } from "./DomainProviderInput";
import { Checkbox } from "flowbite-react";
import { useState } from "react";
import { Img } from "components";
export const DomainEmailSequencerInput = ({ common, handleInputChange }) => {
  const [skip, setSkip] = useState(false);
  return (
    <div className="flex flex-col mb-4">
      {skip && (
        <h1 className="text-2xl font-bold mb-4">
          Domain Provider & Email Outreach Tool Information
        </h1>
      )}
      {!skip && (
        <div className="flex flex-col">
          <DomainProviderInput
            dhp={common.dhp}
            handleInputChange={(field, value) =>
              handleInputChange(`common.dhp.${field}`, value)
            }
          />
          <EmailSequencerInput
            esp={common.esp}
            handleInputChange={(field, value) =>
              handleInputChange(`common.esp.${field}`, value)
            }
          />
        </div>
      )}
      <div className="flex  bg-white-A700 p-2 py-4 rounded-lg flex-col">
        <div className="flex gap-2 items-center">
          <Checkbox
            className="h-4 w-4 checked:text-teal-300"
            value={skip}
            onChange={(e) => setSkip(!skip)}
          />
          <p className="font-semibold">
            I’m not comfortable sharing this credential information. Please skip
            this step.
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <Img
            src="/images/img_alert.png" // Adjust path if needed
            alt="Alert"
            className="h-[22px] w-[22px]"
          />
          <p className="text-sm text-gray-600 mt-2">
            Disclaimer: We recommend sharing this information for seamless
            processing. If you choose not to share, you will need to manually
            update the nameserver and invite team members to the sequencer
            provider to process your order.
          </p>
        </div>
      </div>
    </div>
  );
};
