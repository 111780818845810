import { Button, Input } from "components";
import React, { useState } from "react";
import { toast } from "react-toastify";

import NumericDropdown from "components/NumericDropdown";
import Stepper from "components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Spinner } from "flowbite-react";
import { postOrder } from "api/orderSuccess";
import { DomainInput } from "components/DomainInput";
import SenderNameConfiguration from "components/SenderNameConfiguration";
import { DomainEmailSequencerInput } from "components/DomainEmailSequencerInput";
import { validateDomains, validateMailboxes } from "utils/validations";
import { generateDomains } from "utils/utils";

const steps = [
  {
    title: "Setup Your Domains",
    description: "Set up your domains to proceed.",
  },
  {
    title: "Configure Sender Names",
    description: "Configure sender names for each domain.",
  },
];

export default function GoogleOrder() {
  // FIXME: Subscription prices ids
  // bbuy domain
  // notion page hosting
  const location = useLocation();
  const navigate = useNavigate();
  const { userObj } = useSelector((state) => state.user);
  const { plan, payment } = location.state || {};
  const [finalLoading, setFinalLoading] = useState(false);
  useEffect(() => {
    if (!plan || !payment || !plan.domains || !payment.Data.qty) {
      navigate("/"); // Redirect to home
    }
  }, [plan, payment, navigate]);
  const qty = payment.Data.qty;
  const domains = plan.google.mailbox/2;

  const [data, setData] = useState({
    qty: qty,
    common: {
      dhp: {
        domainHostProvider: [],
        domainHostProviderLink: "",
        domainHostUsername: "",
        domainHostPassword: "",
      },
      esp: {
        esp: [],
        espLink: "",
        emailOutreachUsername: "",
        emailOutreachPassword: "",
      },
    },
    aliases: [],
    aliases_count:plan.google.mailbox,
    domains: generateDomains(domains * qty),
    usersPerDomain: 2,
  });

  const handleInputChange = (path, value) => {
    const keys = path.split(".");
    setData((prev) => {
      let updatedData = { ...prev };
      let nestedData = updatedData;

      // Traverse to the desired field and update the value
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          nestedData[key] = value; // Set the value at the final key
        } else {
          nestedData = nestedData[key]; // Traverse deeper into nested structure
        }
      });

      return updatedData;
    });
  };

  const [currentStep, setCurrentStep] = useState(0);

  const submitOrder = async () => {
    setFinalLoading(true);

    try {
      const response = await postOrder(
        userObj.token,
        userObj.organizationId,
        payment["UUID"],
        data
      );
      navigate("/billing?new-order=true");
    } catch (error) {
      console.error("Error fetching searched:", error);
    }
    setFinalLoading(false);
  };

  const handleNextStep = async () => {
    if (currentStep === 0) {
      const msg = validateDomains(data.domains);
      if (msg) {
        toast.error(msg);
        return;
      }
    }

    if (currentStep === 1) {
      const msg = validateMailboxes(
        data.domains,
        data.aliases,
        data.aliases_count
      );
      if (msg) {
        toast.error(msg);
        return;
      }
      await submitOrder();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex h-full flex-col pr-3 max-h-[85vh] overflow-auto">
      <p className="text-xl font-bold text-gray-900">{plan.reason}</p>

      <Stepper steps={steps} currentStep={currentStep} />

      {/* Step content */}
      <section className="bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="flex flex-col h-full">
              <DomainEmailSequencerInput
                common={data.common}
                handleInputChange={handleInputChange}
              />
              <h1 className="text-2xl font-bold mb-4">Domains Information</h1>
              <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
                <DomainInput
                  qty={data.qty}
                  domains={data.domains}
                  handleInputChange={handleInputChange}
                />
                {/* <div className="flex gap-4 items-center justify-between">
                  <NumericDropdown
                    label="Number of Users Per Domain"
                    value={data.usersPerDomain}
                    onChange={(newValue) =>
                      handleInputChange("usersPerDomain", newValue)
                    }
                    max={3}
                  />
                </div> */}
              </div>
            </div>
          )}
          {currentStep === 1 && (
            <SenderNameConfiguration
              aliases={data.aliases}
              domains={data.domains}
              aliases_count={data.aliases_count}
              handleInputChange={handleInputChange}
            />
          )}
          {finalLoading ? (
            <Spinner className="w-8 h-8" />
          ) : (
            <div
              className={`mt-8 text-center md:mt-16 flex gap-4 ${
                currentStep == 1 ? "" : "justify-center"
              }`}
            >
              {currentStep > 0 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handlePreviousStep}
                >
                  Previous
                </Button>
              )}
              {currentStep <= steps.length - 1 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handleNextStep}
                >
                  {currentStep < steps.length - 1 ? "Next" : "Finish"}
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
