import React from "react";

const NumericDropdown = ({ label, value, onChange, min = 1, max }) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label
          htmlFor="quantity-select"
          className="text-sm font-bold text-gray-900 mb-1"
        >
          {label}
        </label>
      )}
      <select
        name="quantity"
        id="quantity-select"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        className="block w-20 py-2 pl-4 pr-8 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
      >
        {Array.from({ length: max - min + 1 }, (_, i) => (
          <option key={i} value={i + min}>
            {i + min}
          </option>
        ))}
      </select>
    </div>
  );
};

export default NumericDropdown;
