import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { Button, Img, Input } from "components";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { getMailboxes } from "api/mailbox";
import MultipleSelectInput from "components/MultiSelectInput";
import { getDomains } from "api/domain";
import { useLocation } from "react-router-dom";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { DOMAIN_TYPE } from "config/constants";

export default function Mailboxes() {
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMailboxes, setFilteredMailboxes] = useState(null);
  const [provider, setProvider] = useState([]);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.selectedDomain) {
      setSelectedDomain([location.state.selectedDomain]);
    }
  }, [location.state]);

  const loadMailboxes = async () => {
    setLoading(true);
    try {
      const requestBody = {
        domain_ids: selectedDomain.map((domain) => domain.value),
        provider
      };

      const response = await getMailboxes(
        userObj.token,
        userObj.organizationId,
        requestBody
      );
      setResults(response);
      setFilteredMailboxes(response.mailboxes);
    } catch (error) {
      console.error("Error fetching mailboxes:", error);
    } finally {
      setLoading(false);
    }
  };
  const loadDomains = async () => {
    try {
      const dt = await getDomains(userObj.token, userObj.organizationId);
      const domainList = dt.domains.map((item) => ({
        value: item.id,
        label: item.Domain,
      }));
      setDomains(domainList);
    } catch (error) {
      console.error("Error making request:", error);
    }
  };
  useEffect(() => {
    loadDomains();
  }, []);

  useEffect(() => {
    loadMailboxes();
  }, [selectedDomain,provider]);

  useEffect(() => {
    if (results?.mailboxes) {
      const filtered = results.mailboxes.filter(
        (item) =>
          `${item["First Name"]} ${item["Last Name"]}`
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.Email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMailboxes(filtered);
    }
  }, [searchTerm, results]);

  // Prepare CSV data
  const csvData =
    results?.mailboxes.map((item) => ({
      Domain: item.Domain,
      Mailboxes: item.Mailboxes,
    })) || [];
  return (
    <div className="bg-white">
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {results && (
            <>
              <p className="text-xl font-bold text-gray-900">
                Mailboxes{" "}
                {results && results.totalRecords > 0 && (
                  <span>({results.totalRecords})</span>
                )}
              </p>
              <div className="flex flex-col">
                <div className="mt-4 flex justify-between items-center gap-2 sm:flex-col">
                  <div className="w-[60%]">
                    <Input
                      placeholder="Search mailboxes"
                      className="w-full"
                      prefix={<MagnifyingGlassIcon className="w-5 h-5 mr-5" />}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="w-1/2 flex items-start justify-end">
                    <MultipleSelectInput
                      options={DOMAIN_TYPE}
                      label=""
                      placeholder="Select provider"
                      selected={provider}
                      setSelected={setProvider}
                      search={false}
                      className="w-full"
                      multiselect={true}
                    />
                  </div>
                </div>
                {domains && domains.length > 0 && (
                  <div className="w-1/2 mt-2">
                    <MultipleSelectInput
                      options={domains}
                      label=""
                      placeholder="Select a Domain"
                      selected={selectedDomain}
                      setSelected={setSelectedDomain}
                      multiselect={true}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col mt-4 lg:mt-8 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="h-[75vh] overflow-y-auto">
                      <table className="min-w-full lg:divide-gray-200 divide-y">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Provider
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Email
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Sender Name
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Password
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredMailboxes &&
                            filteredMailboxes.map((item, index) => (
                              <tr key={index} className="bg-white">
                                <td className="flex items-start px-4 py-4 justify-center">
                                  <p className="bg-gray-100 text-gray-700 rounded-lg p-1 px-2 text-sm font-medium">
                                    {index + 1}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item["Outlook Order ID"]?.length > 0 ? (
                                    <Img
                                      src="/images/outlook_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : item["Google Order ID"]?.length > 0 ? (
                                    <Img
                                      src="/images/google_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : (
                                    <QuestionMarkCircleIcon className="h-5 w-5" />
                                  )}
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.Email}
                                </td>

                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item["First Name"]} {item["Last Name"]}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item["Mailbox Password"]}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
