import React, { useState } from "react";
import { handleDownloadSampleCSV } from "utils/utils";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Button, Input } from "components";
import Papa from "papaparse"; 
import { toast } from "react-toastify";
import { Spinner } from "flowbite-react";
import axios from "axios";
import {
  ArrowDownOnSquareIcon,
  ClipboardIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

export default function SenderNameConfiguration({
  domains,
  aliases,
  aliases_count,
  handleInputChange,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mailboxLoading, setMailboxLoading] = useState(false);
  const [senderNameMode, setSenderNameMode] = useState("global");
  const [globalSenderNames, setGlobalSenderNames] = useState([
    { first_name: "", last_name: "" },
  ]);

  const handleGlobalSenderNameChange = (index, field, value) => {
    const updatedGlobalSenderNames = [...globalSenderNames];
    updatedGlobalSenderNames[index][field] = value;
    setGlobalSenderNames(updatedGlobalSenderNames);
  };

  const handleSenderNameChange = (domainIndex, senderIndex, field, value) => {
    const updatedDomains = [...domains];
    const updatedSenderNames = [...updatedDomains[domainIndex].sender_names];
    updatedSenderNames[senderIndex][field] = value;
    updatedDomains[domainIndex].sender_names = updatedSenderNames;
    handleInputChange("domains", updatedDomains);
  };

  const addGlobalSenderName = () => {
    setGlobalSenderNames((prev) => [
      ...prev,
      { first_name: "", last_name: "" },
    ]);
  };

  const removeGlobalSenderName = (index) => {
    if (globalSenderNames.length > 1) {
      setGlobalSenderNames((prev) => prev.filter((_, i) => i !== index));
    } else {
      toast.error("At least one sender name is required.");
    }
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.error("Please upload a valid CSV file.");
      return;
    }

    const allowedExtensions = ["csv"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only CSV files are allowed.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;

      // Parse CSV using PapaParse
      Papa.parse(csvData, {
        header: true, // Use the first row as column headers
        skipEmptyLines: true,
        complete: (result) => {
          const parsedData = result.data;

          // Validate required fields: firstname, lastname, alias, domain
          const isValid = parsedData.every((row) =>
            ["firstname", "lastname", "alias", "domain"].every(
              (key) => key in row && row[key].trim() !== ""
            )
          );

          if (!isValid) {
            toast.error(
              "Invalid CSV format. Ensure all rows have firstname, lastname, alias, and domain fields."
            );
            return;
          }

          // Process and update aliases in state
          const formattedData = parsedData.map((row) => ({
            first_name: row.firstname,
            last_name: row.lastname,
            alias: row.alias,
            domain: row.domain,
          }));
          handleInputChange("aliases", formattedData);
          toast.success("CSV uploaded and processed successfully!");
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          toast.error("Error processing the CSV file.");
        },
      });
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      toast.error("Error reading the CSV file.");
    };

    reader.readAsText(file);
  };

  const getMailboxes = async () => {
    // FIXME: Generate for each domain separately
    // Check unique and handle domain check
    try {
      setMailboxLoading(true);
      let finalAliases = [];

      if (senderNameMode === "global") {
        // Global Sender Names Logic
        if (!globalSenderNames.length) {
          toast.error("Global sender names are missing");
          return;
        }
        const totalDomains = domains.length;

        if (totalDomains === 0) {
          toast.error("No domains specified.");
          return;
        }
        
        // Check if any domain name is empty
        const hasEmptyDomain = domains.some((domainObj) => !domainObj.domain.trim());
        
        if (hasEmptyDomain) {
          toast.error("One or more domain names are empty. Please fill them out.");
          return;
        }
        // Calculate aliases per domain
        const aliasesPerDomain = Math.floor(aliases_count / totalDomains);
        const remainingAliases = aliases_count % totalDomains;

        for (let i = 0; i < domains.length; i++) {
          const { domain } = domains[i];
          const domainAliasCount =
            i < remainingAliases ? aliasesPerDomain + 1 : aliasesPerDomain;

          for (let j = 0; j < globalSenderNames.length; j++) {
            const { first_name, last_name } = globalSenderNames[j];
            if (!first_name || !last_name) {
              toast.error(
                `Please fill out or remove the empty fields for global sender ${
                  j + 1
                }`
              );
              return;
            }

            // Calculate aliases per global sender for this domain
            const aliasesPerSender = Math.floor(
              domainAliasCount / globalSenderNames.length
            );
            const remainingSenderAliases =
              domainAliasCount % globalSenderNames.length;

            const numAliases =
              j < remainingSenderAliases
                ? aliasesPerSender + 1
                : aliasesPerSender;

            // API Call for Global Sender Name
            const resp = await axios.get(
              `https://useful-endpoints.vercel.app/generate-aliases?first_name=${first_name}&last_name=${last_name}&num=${numAliases}`
            );

            const aliases =
              resp?.data?.aliases.map((alias) => ({
                alias,
                first_name,
                last_name,
                domain, // Assign the correct domain from data.domains
              })) || [];
            finalAliases = [...finalAliases, ...aliases];
          }
        }
      } else if (senderNameMode === "domain") {
        // Domain-Specific Sender Names Logic
        const totalDomains = domains.length;

        if (totalDomains === 0) {
          toast.error("No domains specified.");
          return;
        }

        // Divide aliases count among domains
        const aliasesPerDomain = Math.floor(aliases_count / totalDomains);
        const remainingAliases = aliases_count % totalDomains;

        for (let i = 0; i < domains.length; i++) {
          const { domain, sender_names } = domains[i];
          if (!domain || !sender_names.length) {
            toast.error(`Domain or sender names missing for domain ${i + 1}`);
            return;
          }

          const domainAliasCount =
            i < remainingAliases ? aliasesPerDomain + 1 : aliasesPerDomain;

          // Calculate aliases per sender for this domain
          const aliasesPerSender = Math.floor(
            domainAliasCount / sender_names.length
          );
          const remainingSenderAliases = domainAliasCount % sender_names.length;

          for (let j = 0; j < sender_names.length; j++) {
            const { first_name, last_name } = sender_names[j];
            if (!first_name || !last_name) {
              toast.error(
                `Please fill out or remove the empty fields for sender ${
                  j + 1
                } in domain ${domain}`
              );
              return;
            }

            const numAliases =
              j < remainingSenderAliases
                ? aliasesPerSender + 1
                : aliasesPerSender;

            // API Call for Domain-Specific Sender Name
            const resp = await axios.get(
              `https://useful-endpoints.vercel.app/generate-aliases?first_name=${first_name}&last_name=${last_name}&num=${numAliases}`
            );

            const aliases =
              resp?.data?.aliases.map((alias) => ({
                alias,
                first_name,
                last_name,
                domain,
              })) || [];
            finalAliases = [...finalAliases, ...aliases];
          }
        }
      }

      handleInputChange("aliases", finalAliases);
      toast.success("Mailboxes generated successfully!");
    } catch (err) {
      console.error("Error generating mailboxes:", err);
      toast.error(err.response?.data?.error || "Unknown error occurred");
    } finally {
      setMailboxLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-xl font-bold">Configure Sender Names</h2>
      <div className="flex gap-2 items-center sm:flex-col sm:items-start">
        {[
          {
            value: "global",
            label: "Use global sender names for all domains",
          },
          {
            value: "domain",
            label: "Customize sender names for each domain",
          },
          { value: "csv", label: "Import using CSV" },
        ].map((mode) => (
          <label key={mode.value} className="flex items-center gap-2">
            <input
              type="radio"
              name="senderNameMode"
              value={mode.value}
              checked={senderNameMode === mode.value}
              onChange={() => setSenderNameMode(mode.value)}
              className="w-4 h-4 border-gray-300 rounded-md text-teal-300 focus:ring-teal-300"
            />
            <span className="text-gray-900 font-medium">{mode.label}</span>
          </label>
        ))}
      </div>
      <div className="flex justify-between flex-col lg:flex-row">
        <div>
          {senderNameMode === "global" && (
            <div className="flex flex-col gap-2">
              <h2 className="text-xl font-bold">Global Sender Names</h2>
              {globalSenderNames.map((sender, index) => (
                <div key={index} className="flex gap-2 items-center">
                  <Input
                    placeholder="First Name"
                    value={sender.first_name}
                    onChange={(e) =>
                      handleGlobalSenderNameChange(
                        index,
                        "first_name",
                        e.target.value
                      )
                    }
                  />
                  <Input
                    placeholder="Last Name"
                    value={sender.last_name}
                    onChange={(e) =>
                      handleGlobalSenderNameChange(
                        index,
                        "last_name",
                        e.target.value
                      )
                    }
                  />
                  <div className="flex gap-1">
                    {globalSenderNames.length > 1 && (
                      <XMarkIcon
                        className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                        onClick={() => removeGlobalSenderName(index)}
                      />
                    )}
                    <PlusIcon
                      className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                      onClick={addGlobalSenderName}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {senderNameMode === "domain" && (
            <div>
              {domains.map((domain, domainIndex) => (
                <div
                  key={domainIndex}
                  className="flex flex-col gap-4 border-b pb-4"
                >
                  <div className="flex justify-between items-center mt-2">
                    <p className="text-lg font-bold">
                      Domain: {domain.domain || `Domain ${domainIndex + 1}`}
                    </p>
                  </div>

                  <div className="flex flex-col gap-2">
                    {domain.sender_names?.map((sender, senderIndex) => (
                      <div
                        key={senderIndex}
                        className="flex gap-2 items-center"
                      >
                        <Input
                          placeholder="First Name"
                          value={sender.first_name}
                          onChange={(e) =>
                            handleSenderNameChange(
                              domainIndex,
                              senderIndex,
                              "first_name",
                              e.target.value
                            )
                          }
                        />
                        <Input
                          placeholder="Last Name"
                          value={sender.last_name}
                          onChange={(e) =>
                            handleSenderNameChange(
                              domainIndex,
                              senderIndex,
                              "last_name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {senderNameMode === "csv" && (
            <div className="flex flex-col gap-5">
              <h2 className="text-xl font-bold">
                Import Sender Names using CSV
              </h2>
              <div className="flex flex-col">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-gray-500">
                    Ensure the CSV file follows this format:{" "}
                    <strong>firstname, lastname, alias, domain</strong>
                  </p>
                  <div className="flex items-center gap-2">
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleCSVUpload}
                      className="border border-gray-300 p-2 rounded-md"
                    />

                    <button
                      onClick={handleDownloadSampleCSV}
                      className="bg-teal-300 rounded hover:bg-teal-400 flex items-center justify-center text-white p-2 text-white-A700"
                    >
                      <ArrowDownOnSquareIcon className="w-7 h-7 mr-2" />
                      <p>Sample CSV</p>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col mt-4 gap-2 w-full bg-gray-100 border border-gray-300 rounded-md p-4">
                  <h2 className="text-lg font-semibold">API Endpoint</h2>
                  <p className="text-sm text-gray-600">
                    Use this endpoint to generate aliases according to your
                    requirements:
                  </p>
                  <div className="flex items-center gap-2 bg-white p-2 border border-gray-300 rounded-md">
                    <p className="text-sm text-gray-800 overflow-x-auto">
                      <code className="px-1 rounded-md">
                        https://useful-endpoints.vercel.app/generate-aliases?first_name=FIRST_NAME&last_name=LAST_NAME&num=NUM_ALIASES
                      </code>
                    </p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "https://useful-endpoints.vercel.app/generate-aliases?first_name=FIRST_NAME&last_name=LAST_NAME&num=NUM_ALIASES"
                        );
                        toast.success("Copied to clipboard!");
                      }}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <ClipboardIcon className="w-5 h-5 " />
                    </button>
                  </div>
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="mt-2 flex items-center gap-2 text-blue-500 hover:underline"
                  >
                    <InformationCircleIcon className="w-5 h-5" />
                    More Info
                  </button>
                </div>
              </div>
            </div>
          )}

          {senderNameMode !== "csv" && (
            <div className="flex mt-6">
              {mailboxLoading ? (
                <Spinner />
              ) : (
                <Button
                  className="rounded-lg bg-teal-300 text-white px-4 py-2 font-semibold"
                  onClick={getMailboxes}
                >
                  Generate Mailboxes
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="mx-4"/>
        <div>
          {aliases.length > 0 && (
            <div className="w-full lg:w-[30vw] gap-3 flex flex-col items-center overflow-y-auto max-h-[50vh] bg-white p-4 bg-gray-100 mt-5">
              <p className="font-bold">Mailbox Names ({aliases.length})</p>
              {aliases.map((item, i) => (
                <div
                  key={i}
                  className="bg-white flex justify-between items-center p-2 rounded-lg w-full shadow-sm border border-gray-200"
                >
                  {/* Index Number as a Count */}
                  <p className="bg-gray-100 text-gray-700 rounded-lg px-3 py-1 text-sm font-medium">
                    {i + 1}
                  </p>

                  {/* Alias and Domain */}
                  <p className="text-gray-900">
                    {item.alias}@{item.domain}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-opacity-90 flex justify-center items-center z-50 bg-white-A700">
          <div className="bg-white rounded-lg w-4/5 max-w-2xl p-6 relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-3 right-3 bg-gray-200 hover:bg-gray-300 text-gray-600 rounded-full p-2"
            >
              ✕
            </button>
            <h2 className="text-xl font-bold">API Endpoint Details</h2>
            <p className="mt-4 text-sm text-gray-600">
              Use the following API endpoint to generate unique aliases:
            </p>
            <div className="bg-gray-100 border border-gray-300 rounded-md p-4 text-sm overflow-x-auto">
              <pre>
                <code>
                  https://useful-endpoints.vercel.app/generate-aliases?first_name=FIRST_NAME&last_name=LAST_NAME&num=NUM_ALIASES
                </code>
              </pre>
            </div>
            <h3 className="text-lg font-semibold mt-4">Parameters</h3>
            <ul className="list-disc ml-5 text-sm text-gray-600">
              <li>
                <strong>first_name</strong> (required): The first name of the
                sender for which aliases need to be generated.
              </li>
              <li>
                <strong>last_name</strong> (required): The last name of the
                sender for which aliases need to be generated.
              </li>
              <li>
                <strong>num</strong> (optional): The number of unique aliases to
                generate. Defaults to 1 if not provided.
              </li>
            </ul>
            <h3 className="text-lg font-semibold mt-4">How to Use</h3>
            <p className="text-sm text-gray-600">
              This API allows you to programmatically generate unique aliases
              based on the provided first and last names. By specifying the{" "}
              <strong>num</strong> parameter, you can control the number of
              aliases to generate in one request.
            </p>
            <ul className="list-disc ml-5 text-sm text-gray-600 mt-2">
              <li>
                To generate 5 aliases for "John Doe", use the endpoint:
                <br />
                <code className="bg-gray-100 p-1 rounded block mt-1">
                  https://useful-endpoints.vercel.app/generate-aliases?first_name=John&last_name=Doe&num=5
                </code>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
