import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, PasswordInput, Text } from "components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import CustomInput from "components/CustomInput";

import { checkPasswordStrength } from "utils/validations";
import { updateUser } from "api/user";
import { useDispatch } from "react-redux";

function UpdateInformationModal({
  user,
  isShowModal,
  onModalClose,
  ...props
}) {
  const [inpval, setInpval] = useState({
    password: "",
    cPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUpdateUser = async () => {
    const {  password, cPassword } = inpval;
    if (password) {
      const { rules, isStrong } = checkPasswordStrength(
        inpval.password,
        inpval.name
      );
      if (!isStrong) {
        return toast.error("Passowrd not strong enought");
      }
      if (password !== cPassword) {
        return toast.error("Passwords do not match!");
      }
    }

    setLoading(true);
    try {
      const response = await updateUser(user.token,user.organizationId, {
        password,
        cPassword,
      });
      toast.success("User updated successfully");
      onModalClose(); // Close the modal on success
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Update Password
        </Text>

        <div className="w-full mb-3 gap-2 flex flex-col">
          <PasswordInput
            setVal={setVal}
            name="password"
            inpval={inpval.password}
            label="New Password"
          />
          <PasswordInput
            placeholder="Enter your password again in here"
            label="Confirm Password"
            setVal={setVal}
            name="cPassword"
            inpval={inpval.cPassword}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleUpdateUser}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const user = useSelector((state) => state.user.userObj);
  const [profile, setProfile] = useState({
    name: user.name,
    email: user.email,
  });

  return (
    <main>
      <div className="py-2">
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
        </div>

        <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
          <div className="w-full pb-1 overflow-x-auto">
            <div className="border-b border-gray-200 mb-5">
              <nav className="flex -mb-px space-x-10">
                <a
                  href="#"
                  className="py-4 text-sm font-medium text-teal-300 transition-all duration-200 border-b-2 border-teal-300 whitespace-nowrap"
                >
                  {" "}
                  Profile{" "}
                </a>
                {/* 
                        <a href="#" className="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Password </a>

                        <a href="#" className="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Team </a>

                        <a href="#" className="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Notification </a>

                       */}
              </nav>
            </div>
          </div>
          <div className="space-y-6">
            <div className="grid grid-cols-2">
              <label
                htmlFor=""
                className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <input
                type="text"
                name=""
                id=""
                placeholder=""
                value={profile.name}
                className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
              />
            </div>

            <div className="grid grid-cols-2">
              <label
                htmlFor=""
                className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
              >
                Email Address
              </label>
              <input
                type="email"
                name=""
                id=""
                placeholder=""
                value={profile.email}
                className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
              />
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <button
              onClick={() => setPasswordModal(true)}
              className="w-1/2 px-6 py-3 bg-teal-300 border  hover:bg-teal-600 text-white-A700 rounded-md"
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
      <UpdateInformationModal
        isShowModal={passwordModal}
        onModalClose={() => setPasswordModal(false)}
        user={user}
      />
    </main>
  );
}
