export const EMAIL_SEQUENCER = [
  { value: "Smartlead", label: "Smartlead" },
  { value: "Instantly", label: "Instantly" },
  { value: "Email Bison", label: "Email Bison" },
  { value: "Other", label: "Other" },
];

export const DOMAIN_PROVIDER = [
  { value: "Porkbun", label: "Porkbun" },
  { value: "Namecheap", label: "Namecheap" },
  { value: "Godaddy", label: "GoDaddy" },
  { value: "HostGator", label: "HostGator" },
  { value: "Ionos", label: "Ionos" },
  { value: "Cloudflare", label: "Cloudflare" },
  { value: "DnSimple", label: "DnSimple" },
  { value: "Other", label: "Other" },
];

export const DOMAIN_TYPE = [
  { value: "Outlook", label: "Outlook" },
  { value: "Google", label: "Google" },
];
