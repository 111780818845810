import React from "react";
import PropTypes from "prop-types";
const shapes = {
  round: "rounded-[22px]",
};
const variants = {
  fill: {
    red_50_01: "bg-red-50_01 text-red-900",
    gray_50: "bg-gray-50 text-gray-600",
    red:"bg-red-900"
  },
  outline: {
    gray_900_33: "border-gray-900_33 border border-solid text-gray-900_99",
    gray_50: "border-gray-50 border border-solid text-teal-900",
  },
  gradient: {
    teal_300_teal_600: "bg-gradient text-gray-50",
    disable: "bg-gray-200",
  },
};
const sizes = {
  sm: "h-[40px] px-[35px] text-base",
  xs: "h-[32px] pl-3 pr-[7px] text-xs",
  lg: "h-[48px] px-[35px] text-base",
  md: "h-[44px] px-[35px] text-sm",
};
const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  disabled = undefined,
  variant = "outline",
  size = "md",
  color = "gray_50",
  ...restProps
}) => {
  if (disabled !== undefined) {
    variant = disabled ? "file" : "gradient";
    color = disabled ? "disable" : "teal_300_teal_600";
  } else {
    disabled = false;
  }
  return (
    <button
      className={`${className} flex items-center justify-center text-center ${
        !disabled && "cursor-pointer"
      } ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]?.[color]) || ""
      }`}
      disabled={disabled}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["sm", "xs", "lg", "md"]),
  variant: PropTypes.oneOf(["fill", "outline", "gradient"]),
  color: PropTypes.oneOf([
    "red_50_01",
    "gray_50",
    "gray_900_33",
    "teal_300_teal_600",
  ]),
};
export { Button };
