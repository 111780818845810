import React from "react";
import MultipleSelectInput from "./MultiSelectInput";
import { Input } from "components";
import { EMAIL_SEQUENCER } from "config/constants";

export const EmailSequencerInput = ({ esp, handleInputChange }) => {
  const {
    esp: selectedEsp,
    espLink,
    emailOutreachUsername,
    emailOutreachPassword,
  } = esp;

  return (
    <>
      <h1 className="text-2xl font-bold mb-4">
        Email Outreach Tool Information
      </h1>
      <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
        <div className="flex gap-4 items-center justify-between">
          <MultipleSelectInput
            options={EMAIL_SEQUENCER}
            label="Email Sequencer"
            placeholder="Which sequencer will you be using for these domains"
            selected={selectedEsp}
            setSelected={(v) => handleInputChange("esp", v)}
            search={false}
            multiselect={false}
          />
          {selectedEsp?.some((item) =>
            ["Other", "Email Bison"].includes(item.value)
          ) && (
            <Input
              className="w-1/2 mt-4"
              placeholder="Email Sequencer Link"
              value={espLink}
              onChange={(e) => handleInputChange("espLink", e.target.value)}
            />
          )}
        </div>
        <div className="flex gap-4 justify-between">
          <Input
            className="w-1/2"
            placeholder="Email Outreach Tool - Username"
            required
            value={emailOutreachUsername}
            onChange={(e) =>
              handleInputChange("emailOutreachUsername", e.target.value)
            }
          />
          <Input
            className="w-1/2"
            placeholder="Email Outreach Tool - Password"
            type="password"
            required
            value={emailOutreachPassword}
            onChange={(e) =>
              handleInputChange("emailOutreachPassword", e.target.value)
            }
          />
        </div>
      </div>
    </>
  );
};
