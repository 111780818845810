import React from "react";
// FIXME: banner
export default function Guide() {
  return (
    <section className="h-full">
      <div
        style={{
          height: "100%",
          overflowY: "auto", // Enables vertical scrolling if content overflows
          border: "1px solid #e0e0e0",
        }}
      >
        <iframe
          src="https://www.notioniframe.com/notion/1s31912v5r5"
          style={{
            width: "100%",
            height: "100%",
            border: "0",
          }}
          title="Notion Guide"
        ></iframe>
      </div>
    </section>
  );
}
