import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";


const postOrder = async (token, organizationId,uuid,data) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${API_VERSION}/order-success/${uuid}?token=${token}&organizationId=${organizationId}`,data,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mailboxes:", error);
    throw error;
  }
};


export { postOrder };
