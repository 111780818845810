import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { BACKEND_URL } from "config/config";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { getStripe } from "utils/getStripe";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
export default function CheckoutForm({ userData, return_url }) {
  const stripePromise = getStripe();
  const { userObj } = useSelector((state) => state.user);
  const fetchClientSecret = useCallback(() => {
    return axios
      .post(
        `${BACKEND_URL}/custom-checkout?token=${userObj.token}&organizationId=${userObj.organizationId}`,
        {
          returnUrl: return_url,
          data: userData,
        }
      )
      .then((response) => {
        return response.data.sessionId; // Return the sessionId from the response
      })
      .catch((error) => {
        console.error("Error fetching session ID:", error);
        toast.error(error.response?.data?.message || "Unknown Error");
        return null;
      });
  }, []);
  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="overflow-y-scroll max-h-[60vh]">
      {options && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}
