// TODO: orders page domain view and explanation
// REstivtion on input like domain and email sequncer empty
// Subscriptions
const API_VERSION = "v1";
// Low sending volume
const LSV_70MS_30G = process.env.REACT_APP_LSV_70MS_30G;
const LSV_70G_30MS = process.env.REACT_APP_LSV_70G_30MS;
const LSV_50G_50MS = process.env.REACT_APP_LSV_50G_50MS;
const LSV_OUTLOOK_149_PRICE_ID = process.env.REACT_APP_LSV_OUTLOOK_149_PRICE_ID;
const LSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_LSV_OUTLOOK_PRICE_ID;
const LSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_LSV_GOOGLE_INBOXES_PRICE_ID;
// Medium Sending Volume
const MSV_70MS_30G = process.env.REACT_APP_MSV_70MS_30G;
const MSV_70G_30MS = process.env.REACT_APP_MSV_70G_30MS;
const MSV_50G_50MS = process.env.REACT_APP_MSV_50G_50MS;
const MSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_MSV_OUTLOOK_PRICE_ID;
const MSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_MSV_GOOGLE_INBOXES_PRICE_ID;
// High Sending Volume
const HSV_70MS_30G = process.env.REACT_APP_HSV_70MS_30G;
const HSV_70G_30MS = process.env.REACT_APP_HSV_70G_30MS;
const HSV_50G_50MS = process.env.REACT_APP_HSV_50G_50MS;
const HSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_HSV_OUTLOOK_PRICE_ID;
const HSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_HSV_GOOGLE_INBOXES_PRICE_ID;
////////////////////////////////////////////////////
// Warmy
const WARMY_OUTLOOK_PRICE_ID = process.env.REACT_APP_WARMY_OUTLOOK_PRICE_ID;
const WARMY_GOOGLE_PRICE_ID = process.env.REACT_APP_WARMY_GOOGLE_PRICE_ID;
// One time payments
const SWAP_DOMAIN_PRICE_ID = process.env.REACT_APP_SWAP_DOMAIN_PRICE_ID;
const INSTANTLY_UPLOAD_PRICE_ID =
  process.env.REACT_APP_INSTANTLY_UPLOAD_PRICE_ID;
const ADD_PROFILE_PICTURE_PRICE_ID =
  process.env.REACT_APP_ADD_PROFILE_PICTURE_PRICE_ID;

const WARMY_OUTLOOK_NAME = "Warmy.io For Outlook";
const WARMY_GOOGLE_NAME = "Warmy.io For Google";

const GOOGLE_ADDONS = {
  [WARMY_GOOGLE_PRICE_ID]: {
    id: "WARMY",
    reason: "Warmy Google Inboxes",
    mode: "subscription",
    name: WARMY_GOOGLE_NAME,
    price: 2,
    type: "monthly",
    domain: 1,
    image: "https://www.warmy.io/images/logo/logo.svg",
  },
};

const OUTLOOK_ADDONS = {
  [WARMY_OUTLOOK_PRICE_ID]: {
    id: "WARMY",
    reason: "Warmy Outlook Inboxes",
    mode: "subscription",
    name: WARMY_OUTLOOK_NAME,
    price: 25,
    type: "monthly",
    domain: 1,
    image: "https://www.warmy.io/images/logo/logo.svg",
  },
  [INSTANTLY_UPLOAD_PRICE_ID]: {
    id: "INSTANTLY_UPLOAD",
    reason: "Instantly Setup 4 Domain",
    mode: "payment",
    name: "Instantly Setup (Per Package)",
    price: 50,
    type: "one-time",
    domain: 4,
    image:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGxvB5umA6JPA/company-logo_200_200/company-logo_200_200/0/1713263394482/instantlyapp_logo?e=2147483647&v=beta&t=5TVlFElks7yzqZmHlCIR0F24aKEQJpsJXhIWaOvJ1y4",
  },
  [ADD_PROFILE_PICTURE_PRICE_ID]: {
    id: "PROFILE_PICTURE_UPLOAD",
    reason: "Profile Picture Upload 4 Domain",
    mode: "payment",
    name: "Profile Picture Upload (Per Package)",
    price: 50,
    type: "one-time",
    domain: 4,
    image:
      "https://w7.pngwing.com/pngs/858/581/png-transparent-profile-icon-user-computer-icons-system-chinese-wind-title-column-miscellaneous-service-logo.png",
  },
};

const MIXED_ADDONS = {
  [WARMY_GOOGLE_PRICE_ID]: {
    id: "WARMY",
    reason: "Warmy Google Inboxes",
    mode: "subscription",
    name: WARMY_GOOGLE_NAME,
    price: 2,
    type: "monthly",
    domain: 1,
    image: "https://www.warmy.io/images/logo/logo.svg",
  },
  [WARMY_OUTLOOK_PRICE_ID]: {
    id: "WARMY",
    reason: "Warmy Outlook Inboxes",
    mode: "subscription",
    name: WARMY_OUTLOOK_NAME,
    price: 25,
    type: "monthly",
    domain: 1,
    image: "https://www.warmy.io/images/logo/logo.svg",
  },
};

const LSV_OUTLOOK_4_DOMAINS = {
  id: "SCALEDMAIL",
  reason: "Scaledmail",
  mode: "subscription",
  name: "SM - Microsoft",
  price: 199,
  domains: 4,
  microsoft: {
    mailbox: 196,
    domains: 4,
  },
  features: ["196 MS Mailboxes", "Up To 29,400 Emails Per Month"],
  link: "/outlook-order",
  frequency: "/monthly",
  addons: OUTLOOK_ADDONS,
};

const LSV_OUTLOOK_149 = {
  [LSV_OUTLOOK_149_PRICE_ID]: {...LSV_OUTLOOK_4_DOMAINS,price:149},
};

const LSV_MAPPING = {
  [LSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 120,
    domains: 15,
    google: {
      mailbox: 30,
    },
    features: ["30 GSuite Mailboxes", "Up To 13,000 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [LSV_OUTLOOK_PRICE_ID]: LSV_OUTLOOK_4_DOMAINS,
  [LSV_50G_50MS]: {
    id: "MIXED_PACKAGE",
    reason: "Low Sending Volume - 50G / 50MS",
    mode: "subscription",
    name: "SM MS 50% - SM Google 50%",
    price: 259,
    domains: 14,
    features: [
      "20 GSuite + 196 MS = 216 Mailboxes",
      "Up To 38,400 Emails Per Month",
    ],
    google: {
      mailbox: 20,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [LSV_70MS_30G]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "Low Sending Volume - 30G / 70MS",
    mode: "subscription",
    price: 229,
    domains: 9,
    features: [
      "10 GSuite + 196 MS = 206 Mailboxes",
      "Up To 33,900 Emails Per Month",
    ],
    google: {
      mailbox: 10,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [LSV_70G_30MS]: {
    id: "MIXED_PACKAGE",
    name: "SM Google 70% - SM MS 30%",
    reason: "Low Sending Volume - 70G / 30MS",
    mode: "subscription",
    price: 189,
    domains: 12,
    features: [
      "20 GSuite + 98 MS = 118 Mailboxes",
      "Up To 24,000 Emails Per Month",
    ],
    google: {
      mailbox: 20,
    },
    microsoft: {
      mailbox: 98,
      domains: 2,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
};

const MSV_MAPPING = {
  [MSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 175,
    domains: 25,
    google: {
      mailbox: 50,
    },
    features: ["50 GSuite mailboxes", "Up To 22,500 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [MSV_OUTLOOK_PRICE_ID]: {
    id: "SCALEDMAIL",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - Microsoft",
    price: 398,
    domains: 8,
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    features: ["392 MS Mailboxes", "Up To 58,800 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    addons: OUTLOOK_ADDONS,
  },
  [MSV_50G_50MS]: {
    id: "MIXED_PACKAGE",
    reason: "Medium Sending Volume - 50G / 50MS",
    mode: "subscription",
    name: "SM MS 50% - SM Google 50%",
    price: 518,
    domains: 18,
    features: [
      "40 GSuite + 392 MS = 432 Mailboxes",
      "Up To 76,800 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [MSV_70MS_30G]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "Medium Sending Volume - 30G / 70MS",
    mode: "subscription",
    price: 458,
    domains: 18,
    features: [
      "20 GSuite + 392 MS = 412 Mailboxes",
      "Up To 67,800 Emails Per Month",
    ],
    google: {
      mailbox: 20,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [MSV_70G_30MS]: {
    id: "MIXED_PACKAGE",
    name: "SM Google 70% - SM MS 30%",
    reason: "Medium Sending Volume - 70G / 30MS",
    mode: "subscription",
    price: 319,
    domains: 24,
    features: [
      "40 GSuite + 196  MS = 236 Mailboxes",
      "Up To 47,400 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
};

const HSV_MAPPING = {
  [HSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 300,
    domains: 50,
    google: {
      mailbox: 100,
    },
    features: ["100 mailboxes", "Up To 45,000 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [HSV_OUTLOOK_PRICE_ID]: {
    id: "SCALEDMAIL",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - Microsoft",
    price: 597,
    domains: 12,
    microsoft: {
      mailbox: 588,
      domains: 12,
    },
    features: ["588 Mailboxes", "Up To 88,200 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    addons: OUTLOOK_ADDONS,
  },
  [HSV_50G_50MS]: {
    id: "MIXED_PACKAGE",
    reason: "High Sending Volume - 50G / 50MS",
    name: "SM MS 50% - SM Google 50%",
    mode: "subscription",
    price: 807,
    domains: 47,
    features: [
      "70 GSuite + 588 MS = 658 Mailboxes",
      "Up To 120,000 Emails Per Month",
    ],
    google: {
      mailbox: 70,
    },
    microsoft: {
      mailbox: 588,
      domains: 12,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [HSV_70MS_30G]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "High Sending Volume - 30G / 70MS",
    price: 717,
    domains: 32,
    mode: "subscription",
    features: [
      "40 GSuite + 588 MS = 628 Mailboxes",
      "Up To 71,280 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 588,
      domains: 12,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [HSV_70G_30MS]: {
    id: "MIXED_PACKAGE",
    name: "SM Google 70% - SM MS 30%",
    reason: "High Sending Volume - 70G / 30MS",
    price: 638,
    domains: 52,
    mode: "subscription",
    features: [
      "80 GSuite + 392 MS = 472 Mailboxes",
      "Up To 94,800 Emails Per Month",
    ],
    google: {
      mailbox: 80,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
};

const PRICE_MAPPING = {
  // Mixed Packages
  ...LSV_MAPPING,
  ...MSV_MAPPING,
  ...HSV_MAPPING,
  // Addons
  ...GOOGLE_ADDONS,
  ...OUTLOOK_ADDONS,
  ...MIXED_ADDONS,
  // Discounted
  ...LSV_OUTLOOK_149,
  // Other
  [SWAP_DOMAIN_PRICE_ID]: {
    id: "DOMAIN_SWAP",
    reason: "Domain Swap",
    name: "Domain Swap",
    mode: "payment",
    price: 25,
    domain: 1,
  },
};

module.exports = {
  PRICE_MAPPING,

  LSV_MAPPING,
  MSV_MAPPING,
  HSV_MAPPING,

  WARMY_GOOGLE_PRICE_ID,
  WARMY_OUTLOOK_PRICE_ID,

  SWAP_DOMAIN_PRICE_ID,
  INSTANTLY_UPLOAD_PRICE_ID,
  ADD_PROFILE_PICTURE_PRICE_ID,

  API_VERSION,
  WARMY_GOOGLE_NAME,
  WARMY_OUTLOOK_NAME,

  LSV_OUTLOOK_PRICE_ID,
  LSV_OUTLOOK_149_PRICE_ID,
  LSV_OUTLOOK_149,
};
