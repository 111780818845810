import React from "react";
import { Button, Input } from "components";
import {
  ArrowDownOnSquareIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { handleDownloadDomainSampleCSV } from "utils/utils";
import Papa from "papaparse";
import { toast } from "react-toastify";
export const DomainInput = ({ qty, domains, handleInputChange }) => {
  const handleDomainChange = (index, field, value) => {
    const updatedDomains = [...domains];
    updatedDomains[index] = {
      ...updatedDomains[index],
      [field]: value,
      sender_names: updatedDomains[index].sender_names || [
        { first_name: "", last_name: "" },
      ],
    };
    handleInputChange("domains", updatedDomains);
  };
  const handleSameRedirect = () => {
    if (domains.length > 0) {
      const firstRedirect = domains[0].redirect_to; // Get the redirect from the first entry
      const updatedDomains = domains.map((domain, index) => {
        return {
          ...domain,
          redirect_to: index === 0 ? domain.redirect_to : firstRedirect, // update all redirects
        };
      });
      handleInputChange("domains", updatedDomains);
    }
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      toast.error("Please upload a valid CSV file.");
      return;
    }
    
    const allowedExtensions = ["csv"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
  
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only CSV files are allowed.");
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const csvData = e.target.result;
  
      // Parse CSV using PapaParse
      Papa.parse(csvData, {
        header: true, // Use the first row as column headers
        skipEmptyLines: true,
        complete: (result) => {
          const parsedData = result.data;
  
          // Validate required fields: domain and redirect
          const isValid = parsedData.every((row) =>
            ["domain", "redirect"].every((key) => key in row)
          );
          if (!isValid) {
            toast.error(
              "Invalid CSV format. Ensure all rows have domain and redirect fields."
            );
            return;
          }
  
          // Calculate how many more domains are needed
          const requiredDomainsCount = domains.length;
          const domainsCountInCSV = parsedData.length;
          const remainingDomainsCount = requiredDomainsCount - domainsCountInCSV;
  
          // Add empty domains if necessary
          const formattedData = [
            ...parsedData.map((row) => ({
              domain: row.domain,
              redirect_to: row.redirect,
              sender_names: [{ first_name: "", last_name: "" }],
            })),
            // Add empty domains for the remaining count
            ...Array(remainingDomainsCount).fill({
              domain: "",
              redirect_to: "",
              sender_names: [{ first_name: "", last_name: "" }],
            }),
          ];
  
          handleInputChange("domains", formattedData);
          toast.success("CSV uploaded and processed successfully!");
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          toast.error("Error processing the CSV file.");
        },
      });
    };
  
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      toast.error("Error reading the CSV file.");
    };
  
    reader.readAsText(file);
  };
  

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <p>
            Enter domain names and redirects (Total: {domains.length} pairs)
          </p>
        </div>

        <div className="max-h-[300px] overflow-y-auto">
          {domains.map((item, index) => (
            <div key={index} className="flex gap-2 items-center mb-2">
              <span className="text-gray-600 font-bold">{index + 1}.</span>
              <Input
                placeholder={`domain${index + 1}.com`}
                value={item.domain}
                onChange={(e) =>
                  handleDomainChange(index, "domain", e.target.value)
                }
              />
              <Input
                placeholder={`redirect${index + 1}.com`}
                value={item.redirect_to}
                onChange={(e) =>
                  handleDomainChange(index, "redirect_to", e.target.value)
                }
              />
              {index == 0 && (
                <div
                  className="flex hover:text-teal-600"
                  onClick={handleSameRedirect}
                >
                  <ClipboardDocumentListIcon className="w-5 h-5 mr-2" />
                  <p className="font-extralight cursor-pointer uppercase">
                    Add Same Redirect
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-center gap-2 w-1/2">
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVUpload}
            className="border border-gray-300 rounded-md"
          />

          <button
            onClick={handleDownloadDomainSampleCSV}
            className="px-2 bg-teal-300 rounded hover:bg-teal-600 flex items-center justify-center text-white-A700"
          >
            <ArrowDownOnSquareIcon className="w-7 h-7 mr-2" />
            <p>Sample CSV</p>
          </button>
        </div>
      </div>
    </div>
  );
};
