import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const findById = async (token, orgId,recordId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${API_VERSION}/record/${recordId}?token=${token}&organizationId=${orgId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription:", error);
    throw error;
  }
};

export { findById };
