import { Elements } from "@stripe/react-stripe-js";
import { Button } from "components";
import CheckoutForm from "components/CheckoutForm";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getStripe } from "utils/getStripe";
import NumericDropdown from "components/NumericDropdown";
import Stepper from "components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { WARMY_GOOGLE_NAME, WARMY_OUTLOOK_NAME } from "config/prices";

const MAX_SELECT = 99;
const steps = [
  {
    title: "Confirm Package",
    description: "Confirm package quntity",
  },
  {
    title: "Finalize",
    description: "Finalize your configuration",
  },
];

export default function Subscribe() {
  const location = useLocation();
  const plan = location.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!plan) {
      navigate("/"); // Redirect to home page
    }
  }, [plan, navigate]);
  const MIN_QTY = 1;

  const [data, setData] = useState({
    qty: MIN_QTY,
  });

  const handleInputChange = (field, value) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const [currentStep, setCurrentStep] = useState(0);

  const [availableAddons, setAvailableAddons] = useState(plan.addons);
  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleAddonAdd = (addonName) => {
    const addon = availableAddons.find((a) => a.name === addonName);
    if (addon) {
      const newAddons = [...selectedAddons, addon];
      setSelectedAddons(newAddons);
      setAmount(calculateBillingBreakdown(data, newAddons, plan));
      setAvailableAddons((prev) => prev.filter((a) => a.name !== addonName));
    }
  };

  const handleAddonRemove = (addonName) => {
    const addon = selectedAddons.find((a) => a.name === addonName);
    if (addon) {
      setAvailableAddons((prev) => [...prev, addon]);
      const newAddons = selectedAddons.filter((a) => a.name !== addonName);
      setAmount(calculateBillingBreakdown(data, newAddons, plan));
      setSelectedAddons(newAddons);
    }
  };

  useEffect(() => {
    setAmount(calculateBillingBreakdown(data, selectedAddons, plan));
  }, [data.qty]);

  function calculateBillingBreakdown(data, selectedAddons, sub) {
    const totalDomains = data.qty;
    const google_mailboxes = plan.google?.mailbox || 0;
    const microsoft_domains = sub.microsoft?.domains || 0;
    let monthlyRecurring = sub.price * data.qty;

    const monthlyOrSubscriptionAddons = selectedAddons.filter(
      (addon) => addon.type !== "one-time"
    );

    const addonDetails = [];

    monthlyOrSubscriptionAddons.forEach((addon) => {
      let addonCost = 0;
      let total_qty = 0;
      if (addon.name === WARMY_OUTLOOK_NAME) {
        total_qty = data.qty * microsoft_domains;
        addonCost = addon.price * total_qty;
      } else if (addon.name === WARMY_GOOGLE_NAME) {
        total_qty = data.qty * google_mailboxes;
        addonCost = addon.price * total_qty;
      }

      monthlyRecurring += addonCost;

      addonDetails.push({
        ...addon,
        total_qty,
        total_cost: addonCost,
      });
    });

    const oneTimeAddons = selectedAddons.filter(
      (addon) => addon.type === "one-time"
    );
    let oneTimeTotal = 0;
    oneTimeAddons.forEach((addon) => {
      let addonCost = 0;
      let total_qty = 0;
      if (
        [
          "Instantly Setup (Per Package)",
          "Profile Picture Upload (Per Package)",
        ].includes(addon.name)
      ) {
        total_qty = data.qty * microsoft_domains/4;
        addonCost = addon.price * total_qty;
      }
      oneTimeTotal += addonCost;
      addonDetails.push({
        ...addon,
        total_qty,
        total_cost: addonCost,
      });
    });

    const totalDueToday = monthlyRecurring + oneTimeTotal;

    return {
      monthlyRecurring,
      oneTimeTotal,
      totalDueToday,
      totalDomains,
      addonDetails,
    };
  }

  const [amount, setAmount] = useState({
    addonDetails: [],
    monthlyRecurring: 0,
    oneTimeTotal: 0,
    totalDueToday: 0,
    totalDomains: 0,
  });

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex h-full flex-col pr-3 max-h-[85vh] overflow-auto">
      <p className="text-xl font-bold text-gray-900">Bring Your Own Domain</p>

      <Stepper steps={steps} currentStep={currentStep} />

      {/* Step content */}
      <section className="bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="grid lg:grid-cols-2 lg:gap-x-10">
              <div>
                {/* Package Details */}
                <div className="overflow-hidden bg-white-A700 rounded-md">
                  <div className="px-4 py-6 sm:p-6 lg:p-8">
                    <h2 className="text-2xl font-bold text-black">
                      Subscribe to {plan.name}
                    </h2>

                    <div className="flow-root">
                      <div className="divide-y divide-gray-700">
                        <div>
                          <ul className="divide-y divide-gray-200">
                            {/* Selected Package */}
                            <li className="flex py-7">
                              <div className="flex-1 ml-5">
                                <div className="relative sm:grid sm:grid-cols-2 sm:gap-x-5 sm:pr-0">
                                  <div>
                                    <p className="text-base font-bold text-gray-900">
                                      {plan.mini_description}
                                    </p>
                                    <p className="mt-1.5 text-sm font-medium text-gray-500">
                                      {plan.frequency}
                                    </p>
                                  </div>
                                  <ul className="text-sm text-gray-600 space-y-1 mb-4">
                                    {plan.features.map((feature, index) => (
                                      <li
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <CheckCircleIcon className="mr-2 h-6 w-6" />{" "}
                                        {feature}
                                      </li>
                                    ))}
                                  </ul>
                                  <div className="flex items-end justify-between mt-3 sm:flex-col">
                                    <div>
                                      {data.qty > 1 ? (
                                        <p className="text-base font-bold text-gray-900">
                                          {data.qty} x ${plan.price} = $
                                          {plan.price * data.qty}.00
                                        </p>
                                      ) : (
                                        <p className="text-base font-bold text-gray-900">
                                          ${plan.price * data.qty}.00
                                        </p>
                                      )}
                                    </div>
                                    <NumericDropdown
                                      value={data.qty}
                                      max={MAX_SELECT}
                                      min={MIN_QTY}
                                      onChange={(e) =>
                                        handleInputChange(
                                          "qty",
                                          parseInt(e, 10)
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>

                            {/* Selected Addons */}
                            {amount.addonDetails.map((addon, i) => (
                              <li key={i}>
                                <div className="flex py-7">
                                  {addon.image && (
                                    <div className="flex-shrink-0">
                                      <img
                                        className="object-cover w-16 h-16 rounded-lg"
                                        src={addon.image}
                                        alt={addon.name}
                                      />
                                    </div>
                                  )}
                                  <div className="flex-1 ml-5">
                                    <div className="relative sm:grid sm:grid-cols-2 sm:gap-x-5 sm:pr-0">
                                      <div className="pr-9 sm:pr-5">
                                        <p className="text-base font-bold text-gray-900">
                                          {addon.name}
                                        </p>
                                        <p className="mt-1.5 text-sm font-medium text-gray-500">
                                          /{addon.type}
                                        </p>
                                      </div>
                                      <div className="flex items-end justify-between mt-3 sm:justify-end sm:pr-14 sm:items-start sm:mt-0">
                                        <p className="text-base font-bold text-gray-900">
                                          {addon.breakdown}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center justify-between w-full">
                                  <div>
                                    <p className="text-base font-bold text-gray-900">
                                      {addon.total_qty} x ${addon.price} = $
                                      {addon.total_cost}.00
                                    </p>
                                  </div>
                                  <XMarkIcon
                                    className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                                    onClick={() =>
                                      handleAddonRemove(addon.name)
                                    }
                                  ></XMarkIcon>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <hr className="border-gray-200 mt-7" />

                          {/* Total Summary */}
                          <div className="flex items-center justify-between mt-6">
                            <p className="text-base font-bold text-gray-900">
                              Total Due Today
                            </p>
                            <p className="text-base font-bold text-gray-900">
                              ${amount.totalDueToday.toFixed(2)}
                            </p>
                          </div>

                          {amount.oneTimeTotal > 0 && (
                            <div className="mt-6">
                              <p className="text-base font-bold text-gray-900">
                                Subscribe to {plan.title}
                              </p>
                              <p className="text-sm text-gray-700">
                                ${amount.monthlyRecurring.toFixed(2)} / month
                                after the first month
                              </p>
                              <p className="text-sm text-gray-500 mt-1">
                                One-time addons included in today’s charge.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Addons Section */}
              {availableAddons?.length > 0 && (
                <div>
                  <div className="flex justify-center">
                    <h1 className="text-2xl font-bold text-gray-900">ADDONS</h1>
                  </div>
                  <hr className="mt-8 border-gray-200 lg:mt-4" />

                  <div className="flow-root mt-7">
                    <ul className="divide-y divide-gray-200">
                      {availableAddons.map((addon, i) => (
                        <li className="flex py-7" key={i}>
                          {addon.image && (
                            <div className="flex-shrink-0">
                              <img
                                className="object-cover sm:w-8 sm:h-8 w-16 h-16 rounded-lg"
                                src={addon.image}
                                alt={addon.name}
                              />
                            </div>
                          )}
                          <div className="flex-1 ml-5">
                            <div className="relative sm:grid sm:grid-cols-2 sm:gap-x-5 sm:pr-0">
                              <div className="pr-9 sm:pr-5">
                                <p className="text-base font-bold text-gray-900">
                                  {addon.name}
                                </p>
                                <p className="mt-1.5 text-sm font-medium text-gray-500">
                                  /{addon.type}
                                </p>
                              </div>
                              <div className="flex items-end justify-between mt-3 sm:flex-col">
                                <p className="text-base font-bold text-gray-900">
                                  ${addon.price}.00
                                </p>
                                <div className="flex">
                                  <PlusIcon
                                    className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                                    onClick={() => handleAddonAdd(addon.name)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}

          {currentStep === 1 && (
            <div className="text-center">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  userData={{
                    priceId: plan.priceId,
                    qty: data.qty,
                    addons: amount?.addonDetails || [],
                  }}
                  return_url={`${window.location.origin}/order-success`}
                />
              </Elements>
            </div>
          )}

          <div
            className={`mt-8 text-center md:mt-16 flex gap-4 ${
              currentStep == 1 ? "" : "justify-center"
            }`}
          >
            {currentStep > 0 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handlePreviousStep}
              >
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handleNextStep}
              >
                {currentStep < steps.length - 1 ? "Next" : "Finish"}
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
