import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  PasswordInput,
  Warning
} from "components";
import { useNavigate } from "react-router-dom";
import { signUp } from "api/auth";
import { checkPasswordStrength, isValidEmail } from "utils/validations";
import { toast } from "react-toastify";
import { BACKEND_URL } from "config/config";
import { Spinner } from "flowbite-react";
export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("Account Created Successfully. Login Now");
  const [cBtn, setCBtn] = useState(true);
  const [pVal, setPVal] = useState({ 1: true, 2: true, 3: true });
  const [inpval, setInpval] = useState({
    name: "",
    organization: "",
    email: "",
    password: "",
    cPassword: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password") {
      const { rules, isStrong } = checkPasswordStrength(
        inpval.password,
        inpval.name
      );
      setPVal(rules);
    }
    if (name === "email") isValidEmail(value);
  };
  useEffect(() => {
    setError("");
    const { rules, isStrong } = checkPasswordStrength(
      inpval.password,
      inpval.name
    );
    const con =
      inpval.name &&
      isValidEmail(inpval.email) &&
      isStrong &&
      inpval.password === inpval.cPassword;
    setCBtn(con);
  }, [inpval]);

  const handleSignup = async () => {
    const { name, email, password, organization } = inpval; // Destructure within signUp
    setError("")
    setLoading(true);
    try {
      await signUp({ name, email, password, organization });
      setError("Account Created Successfully. Login Now");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      } else {
        setError("Failed to register. Please try again later.");
      }
    }
    setLoading(false);
  };
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(
        `${window.location.origin}/success`
      );
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error("Google login error:", error);
    }
  };
  return (
    <section className="py-6 bg-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center mb-6">
            <img
              className="w-auto h-24 mx-auto"
              src="logo.png"
              alt="Scaledmail"
            />

            {/* <p className="mt-4 text-sm font-medium text-gray-500">
              Create New Account.
            </p> */}
          </div>
          {error && (
            <Warning
              text={error}
              showImage={false}
              isSuccess={
                error === "Account Created Successfully. Login Now"
                  ? true
                  : false
              }
            />
          )}
          <div className="mt-6">
            <button
              onClick={handleGoogleLogin}
              type="button"
              className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
            >
              <img
                className="w-5 h-5 mr-2"
                src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg"
                alt=""
              />
              Sign up with Google
            </button>
          </div>

          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>

            <div className="relative flex justify-center">
              <span className="px-2 text-sm text-gray-400 bg-white"> or </span>
            </div>
          </div>

          <div className="mt-4">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="org"
                  className="text-sm font-bold text-gray-900"
                >
                  Organization Name
                </label>
                <div className="mt-2">
                  <input
                    // type="text"
                    name="organization"
                    onChange={setVal}
                    value={inpval.organization}
                    placeholder="Organization name here"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-[1px] border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="text-sm font-bold text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    // type="text"
                    name="name"
                    onChange={setVal}
                    value={inpval.name}
                    placeholder="Name here"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-[1px] border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    // type="email"
                    name="email"
                    onChange={setVal}
                    value={inpval.email}
                    placeholder="Email address"
                    className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-sm font-bold text-gray-900"
                  >
                    Password
                  </label>
                </div>
                <PasswordInput
                  name="password"
                  setVal={setVal}
                  inpval={inpval.password}
                />
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-sm font-bold text-gray-900"
                  >
                    Confirm Password
                  </label>
                </div>
                <div className="mt-2">
                  <PasswordInput
                    name="cPassword"
                    setVal={setVal}
                    inpval={inpval.cPassword}
                  />
                </div>
              </div>

              <div>
                <button
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-teal-300 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300 hover:bg-teal-600 text-white-A700"
                  disabled={!cBtn}
                  onClick={handleSignup}
                >
                  {loading ? <Spinner /> : "Sign Up"}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm font-medium text-gray-900">
              Already have an account?
              <a href="sign-in" className="font-bold hover:underline">
                Sign in now
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
