import React from "react";
import PropTypes from "prop-types";
const shapes = {
  round: "rounded-lg",
};
const variants = {
  fill: {
    red_50_01: "bg-red-50_01 text-lime-900_01",
    gray_50: "bg-gray-50 text-gray-900",
    red_50: "bg-red-50 text-red-700",
  },
  outline: {
    gray_900_33: "border-gray-900_33 border border-solid text-gray-900_99",
  },
};
const sizes = {
  xs: "h-[40px] pl-2 pr-[35px] text-base",
  md: "h-[56px] px-4 text-base",
  sm: "h-[48px] px-3.5 text-base",
};
const Input = React.forwardRef(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "outline",
      size = "sm",
      color = "gray_900_33",
      ...restProps
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (onChange) onChange(e);
    };
    return (
      <>
        <div
          className={`${className} flex items-center justify-center self-stretch tracking-[0.50px] text-base border border-solid rounded-lg  ${
            (shape && shapes[shape]) || ""
          } ${variants[variant]?.[color] || variants[variant] || ""} ${
            sizes[size] || ""
          }`}
        >
          {!!label && label}
          {!!prefix && prefix}
          {/* FIXME: SEARCH */}
          <input
            ref={ref}
            type={type}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            {...restProps}
            className={`placeholder-gray-400 placeholder-opacity-80 placeholder:font-light border-none ${
              variants[variant]?.[color] || variants[variant] || "bg-gray-50"
            }`}
          />
          {!!suffix && suffix}
        </div>
      </>
    );
  }
);
Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "md", "sm"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf(["red_50_01", "gray_50", "red_50", "gray_900_33"]),
};
export { Input };
