import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getPayments = async (token, organizationId, subscriptions, page, limit) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${VERSION}/payment?token=${token}&organizationId=${organizationId}&subscriptions=${subscriptions}&page=${page}&limit=${limit}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching payments:", error);
    throw error;
  }
};


export { getPayments };
