import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const updateUser = async (token,organizationId,data) => {
  try {
    const response = await axios.put(`${BACKEND_URL}/api/${API_VERSION}/user?token=${token}&organizationId=${organizationId}`, data);

    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

const discountAvailable = async (token,orgId) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/${API_VERSION}/available?token=${token}&organizationId=${orgId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export { discountAvailable,updateUser };
