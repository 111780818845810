import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getNotifications = async (token, organizationId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${VERSION}/notification?organizationId=${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mailboxes:", error);
    throw error;
  }
};

const markNotificationResolved = async (token,organizationId, recordId) => {
  try {
    const response = await axios.put(
      `${BACKEND_URL}/api/${VERSION}/notification/${recordId}/resolve?token=${token}&organizationId=${organizationId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error updating notification status:", error);
    throw error;
  }
};


export { getNotifications,markNotificationResolved };
