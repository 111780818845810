import { DOMAIN_PROVIDER } from "config/constants";
import React from "react";
import MultipleSelectInput from "./MultiSelectInput";
import { Input } from "components";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export const DomainProviderInput = ({ dhp, handleInputChange }) => {
  const {
    domainHostProvider,
    domainHostProviderLink,
    domainHostUsername,
    domainHostPassword,
  } = dhp;

  return (
    <>
      <h1 className="text-2xl font-bold mb-4">
        Domain Host Provider Information
      </h1>
      <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
        <div className="flex gap-4 items-center justify-between">
          <MultipleSelectInput
            options={DOMAIN_PROVIDER}
            label="Your Domain Host Provider"
            placeholder="Which sequencer will you be using for these domains"
            selected={domainHostProvider}
            setSelected={(v) => handleInputChange("domainHostProvider", v)}
            search={false}
            multiselect={false}
          />
        </div>
        {domainHostProvider?.some((item) => item.value === "Other") && (
          <Input
            className="w-1/2"
            placeholder="Other Domain Host Provider Link"
            required
            value={domainHostProviderLink}
            onChange={(e) =>
              handleInputChange("domainHostProviderLink", e.target.value)
            }
          />
        )}

        <div className="flex justify-between gap-4">
          <Input
            className="w-1/2"
            placeholder="Domain Host Provider - Username"
            required
            value={domainHostUsername}
            onChange={(e) =>
              handleInputChange("domainHostUsername", e.target.value)
            }
          />
          <Input
            className="w-1/2"
            placeholder="Domain Host Provider - Password"
            type="password"
            required
            value={domainHostPassword}
            onChange={(e) =>
              handleInputChange("domainHostPassword", e.target.value)
            }
          />
        </div>
        <div className="flex gap-2 items-center">
          <InformationCircleIcon className="w-5 h-5" />
          <p className="text-sm text-gray-600">
            Please disable 2FA temporarily to ensure a smooth setup process.
          </p>
        </div>
      </div>
    </>
  );
};
