import { getPayments } from "api/payment";
import { SelectBox } from "components";
import { Spinner } from "flowbite-react";
import OrderDetail from "modals/OrderDetail";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatTimestamp } from "utils/utils";
import { default as ModalProvider } from "react-modal";
import {
  ArrowLongRightIcon,
  CheckCircleIcon,
  LinkIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Text } from "components";
import { useLocation, useSearchParams, useParams } from "react-router-dom"; //Import `useParams`

function NewOrder({ isShowModal, onModalClose, handleReload, ...props }) {
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center p-8 bg-white-A700 relative mt-6 overflow-hidden bg-white rounded-lg shadow md:mt-10">
        <div className="flex items-center justify-between w-full mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Order Confirmed
          </Text>
          <XMarkIcon
            className="h-10 w-10 cursor-pointer"
            onClick={() => onModalClose(false)}
          />
        </div>
        <div>
          {/* <div class="absolute top-4 right-4">
            <button
              type="button"
              class="inline-flex items-center justify-center px-4 py-3 text-xs font-bold text-gray-900 transition-all duration-200 bg-gray-100 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-200"
            >
              View invoice
            </button>
          </div> */}
          <div class="px-4 py-6 sm:px-8 sm:py-10">
            <div class="-my-8 divide-y divide-gray-200">
              <div class="pt-16 pb-8 text-center sm:py-8">
                <CheckCircleIcon class="w-10 h-10 mx-auto text-green-500" />
                <h1 class="mt-4 text-2xl font-bold text-gray-900">
                  We received your order!
                </h1>
                <p class="mt-2 text-sm font-normal text-gray-600">
                  Your order is completed
                </p>
              </div>
              <div class="py-8">
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-8 sm:gap-x-20">
                  <div>
                    <h2 class="text-xs font-bold tracking-widest text-gray-400 uppercase">
                      You will get invite to slack please accept it for better
                      customer support
                    </h2>
                    <div className="flex items-center justify-center mt-3">
                      <LinkIcon className="w-5 h-5 mr-2" />
                      <a
                        className="text-sm font-medium text-gray-600 hover:text-teal-300 cursor-pointer"
                        href="/guide"
                      >
                        Refer to the manual guide to learn about sending and
                        warm-up volume settings guidelines and more.
                      </a>
                    </div>
                    <div className="flex items-center justify-center mt-1">
                      <span className="text-sm font-medium text-gray-600">
                        You will receive a Slack invite for better customer
                        support at the registered email address. Our standard
                        fulfillment time is 48-72 hours.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

function Billing() {
  const { orderId } = useParams(); // Get orderId from URL
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Subscriptions");
  const [filteredData, setFilteredData] = useState(null);

  const [searchParams] = useSearchParams(); // React Router hook for query parameters
  const [newOrderModal, setNewOrderModal] = useState(false);

  const loadData = async (type) => {
    setLoading(true);
    try {
      const dt = await getPayments(
        userObj.token,
        userObj.organizationId,
        type === "Subscriptions" ? true : false,
        0,
        100
      );
      if (type === "Subscriptions") {
        setSubscriptions(dt);
      } else if (type === "Invoices") {
        setInvoices(dt);
      }
    } catch (error) {
      console.error(`Error loading ${type}:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.get("new-order")) {
      setNewOrderModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    loadData(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (orderId && subscriptions && subscriptions.data) {
      const found = subscriptions.data.find(
        (subscription) => subscription.id === orderId
      );
      if (found) {
        setFilteredData([found]); // Set the array containing the matched element
      } else {
        setFilteredData(null); // Clear filter if not found
      }
    } else {
      setFilteredData(null);
    }
  }, [orderId, subscriptions]);

  const handleOrderClick = (result) => {
    setShowModal(result);
  };

  return (
    <main>
      <div className="py-2">
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-gray-900">Billing</h1>
        </div>
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <div className="w-full pb-1 overflow-x-auto">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px space-x-4">
                {["Subscriptions", "Invoices"].map((tab) => (
                  <div
                    key={tab}
                    className={`py-4 text-sm font-medium transition-all duration-20 whitespace-nowrap ${
                      selectedTab === tab
                        ? "border-b-2 text-teal-300 border-teal-300"
                        : "cursor-pointer"
                    }`}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </div>
                ))}
              </nav>
            </div>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <>
              <div
                className="flex flex-col mt-4"
                style={{ height: "400px", overflowY: "scroll" }}
              >
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    {selectedTab === "Subscriptions" && (
                      <table className="min-w-full lg:divide-y lg:divide-gray-200">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0">
                              ID
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Product
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Date
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Price
                            </th>
                            <th className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                          {(filteredData
                            ? filteredData
                            : subscriptions?.data || []
                          ).map((result, i) => (
                            <tr
                              key={i}
                              className="hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleOrderClick(result)}
                            >
                              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0">
                                #{result.id.replace("rec", "")}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap">
                                <div className="inline-flex items-center">
                                  {result["Description"].includes(
                                    "Custom Order"
                                  )
                                    ? "Custom Order"
                                    : result["Description"].length > 100
                                    ? result["Description"].substring(0, 100) +
                                      "..."
                                    : result["Description"]}
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                                {formatTimestamp(result["Created At"])}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                ${result.Amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {selectedTab === "Invoices" && (
                      <table className="min-w-full lg:divide-y lg:divide-gray-200">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0">
                              ID
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Product
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Date
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Price
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Type
                            </th>
                            <th className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                          {(filteredData
                            ? filteredData
                            : invoices?.data || []
                          ).map((result, i) => (
                            <tr
                              key={i}
                              className="hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleOrderClick(result)}
                            >
                              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0">
                                #{result.id.replace("rec", "")}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap">
                                <div className="inline-flex items-center">
                                  {result["Description"].includes(
                                    "Custom Order"
                                  )
                                    ? "Custom Order"
                                    : result["Description"]}
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                                {formatTimestamp(result["Created At"])}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                ${result.Amount}
                              </td>
                              <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                {result["Type"]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
              {showModal && (
                <OrderDetail
                  userObj={userObj}
                  showModal={showModal !== null}
                  setShowModal={setShowModal}
                  selectedOrder={showModal}
                />
              )}
              <NewOrder
                isShowModal={newOrderModal}
                onModalClose={setNewOrderModal}
              />
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default Billing;
// FIXME: invoice and subscription pages not same
