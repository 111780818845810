import { useEffect, useState } from "react";
import { default as ModalProvider } from "react-modal";
import { toast } from "react-toastify";
import { Spinner } from "flowbite-react";
import { Button, Text } from "components";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { findById } from "api/airtable";
import { formatTimestamp } from "utils/utils";
import { cancelSubscription, downloadInvoice } from "api/stripe";
import { useNavigate } from "react-router-dom";
import { getPendingOrder } from "api/orderHistory";

const ListItem = ({ label, value, circle = false }) => (
  <li className="flex items-center justify-between">
    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
      {label}
    </p>
    {circle ? (
      <p className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1">
        {value}
      </p>
    ) : (
      <p className="text-base font-bold text-gray-900">{value}</p>
    )}
  </li>
);

const DomainView = ({ domains, status }) => {
  return (
    <>
      {/* <ListItem label="Description" value={data["Nickname"]} /> */}
      <li className="flex items-center justify-between">
        <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
          Domains
        </p>
        <div className="flex gap-2 overflow-x-scroll max-w-md py-3">
          {domains?.map((name, i) => (
            <p
              key={i}
              className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1"
            >
              {name}
            </p>
          ))}
        </div>
      </li>
      <ListItem label="Total Domains" value={domains?.length} />
      <ListItem label="Status" value={status} circle={true} />
    </>
  );
};

export default function OrderDetail({
  userObj,
  showModal,
  setShowModal,
  selectedOrder,
}) {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getOrderType = () => {
    const isMixedOrder =
      selectedOrder["Mixed Order"] && selectedOrder["Mixed Order"].length > 0;
    const hasGoogleInboxes =
      selectedOrder["Google Inboxes"] &&
      selectedOrder["Google Inboxes"].length > 0;
    const hasOrders =
      selectedOrder["Orders"] && selectedOrder["Orders"].length > 0;

    switch (true) {
      case isMixedOrder && hasGoogleInboxes && hasOrders:
        return {
          type: "Mixed",
          id: selectedOrder["Mixed Order"][0],
        };
      case hasGoogleInboxes && !isMixedOrder && !hasOrders:
        return {
          type: "Google",
          id: selectedOrder["Google Inboxes"][0],
        };
      case hasOrders && !isMixedOrder && !hasGoogleInboxes:
        return {
          type: "Outlook",
          id: selectedOrder["Orders"][0],
        };
      default:
        return { type: "Other", id: null };
    }
  };
  const loadOrder = async () => {
    // FIXME Do for warmy and other invoice downlaod
    const { type, id } = getOrderType();
    if (type != "Other" && id) {
      try {
        setLoading(true);
        const resp = await findById(userObj.token, userObj.organizationId, id);
        setData({ type, ...resp });
      } catch (error) {
        console.error("Error in getSubscription:", error);
        toast.error(error?.response?.data?.message || "Unknown Error");
      }
      setLoading(false);
    }
  };

  const handleFillForm = async () => {
    try {
      setBtnLoader(true);
      const data = await getPendingOrder(
        userObj.token,
        userObj.organizationId,
        selectedOrder.id
      );
      window.location.href =`${window.location.origin}/order-success/${data.id}`;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unknown Error");
    }
    setBtnLoader(false);
  };

  const handleDownloadInvoice = async () => {
    try {
      // FIXME: Check proper invoice
      setBtnLoader(true);
      const { invoice_pdf } = await downloadInvoice(
        userObj.token,
        userObj.organizationId,
        selectedOrder["Type ID"]
      );
      if (invoice_pdf) {
        const link = document.createElement("a");
        link.href = invoice_pdf;
        link.download = `invoice-${selectedOrder["Type ID"]}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Invoice PDF not available.");
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      toast.error("Error downloading invoice.");
    }
    setBtnLoader(false);
  };

  const handleCancelOrder = async () => {
    // FIXME: Toast for cancel
    try {
      if (!window.confirm("Are you sure you want to cancel this subscription?"))
        return;
      setBtnLoader(true);

      const resp = await cancelSubscription(
        userObj.token,
        userObj.organizationId,
        { subId: data["Subscription ID"], id: data["Payment ID"][0] }
      );
      if (resp.success) {
        toast.success("Subscription canceled successfully.");
        // Optionally update the UI or fetch updated subscription data
      } else {
        toast.error("Failed to cancel the subscription.");
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      toast.error("Error canceling subscription.");
    } finally {
      // Hide loader
      setBtnLoader(false);
    }
  };
  const navigate = useNavigate();
  const handleUpdateOrder = async () => {
    navigate(`/update-order/${selectedOrder.id}`, {
      state: { domainOrder: data, payment: selectedOrder },
    });
  };

  useEffect(() => {
    loadOrder();
  }, [selectedOrder]);

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-gray-50 rounded-lg max-h-[80%]">
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Order Details - {selectedOrder.Type}
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="overflow-y-scroll">
              <Text size="md" as="p" className="tracking-[0.18px]">
                {selectedOrder.Description}
              </Text>
              {selectedOrder["Payment Status"] == "Form Data" ? (
                <div className="mt-10">
                  {btnLoader ? (
                    <Spinner />
                  ) : (
                    <Button
                      variant="gradient"
                      color="teal_300_teal_600"
                      className="tracking-[1.25px] uppercase font-medium min-w-[150px] rounded-md"
                      onClick={handleFillForm}
                    >
                      Fill Form
                    </Button>
                  )}
                </div>
              ) : (
                <ul className="mt-6 space-y-5">
                  <li className="flex items-center justify-between">
                    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                      Amount
                    </p>
                    <p className="text-base font-bold text-gray-900">
                      ${selectedOrder["Amount"]}
                    </p>
                  </li>
                  <li className="flex items-center justify-between">
                    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                      Payment Status
                    </p>
                    <p className="text-base font-bold text-gray-900">
                      {selectedOrder["Payment Status"]}
                    </p>
                  </li>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Spinner className="h-6 w-6" />
                    </div>
                  ) : (
                    data && (
                      <>
                        <ListItem
                          label="Invoice Start Date"
                          value={formatTimestamp(data["Invoice Start Date"])}
                        />
                        <ListItem
                          label="Invoice End Date"
                          value={formatTimestamp(data["Invoice End Date"])}
                        />

                        {data.type === "Mixed" ? (
                          <>
                            <div className="bg-white-A700 p-2">
                              <p className="text-sm font-bold">Outlook</p>
                              <DomainView
                                domains={data["Outlook Domain"]}
                                status={data["Outlook Status"][0]}
                              />
                            </div>

                            <div className="bg-white-A700 p-2">
                              <p className="text-sm font-bold">Google</p>
                              <DomainView
                                domains={data["Google Domain"]}
                                status={data["Google Status"][0]}
                              />
                            </div>
                          </>
                        ) : (
                          <DomainView
                            domains={data.Domain}
                            status={data.Status}
                          />
                        )}
                        <div className="mt-4 flex items-center justify-end gap-2">
                          {btnLoader ? (
                            <Spinner />
                          ) : (
                            <>
                              <Button
                                variant="gradient"
                                color="teal_300_teal_600"
                                className="rounded-md"
                                onClick={handleDownloadInvoice}
                              >
                                Download Invoice
                              </Button>

                              {/* <Button
                              className="bg-teal-300 hover:bg-teal-400 text-white-A700 rounded-lg"
                              onClick={handleUpdateOrder}
                            >
                              Update Order
                            </Button> */}

                              <Button
                                variant="gradient"
                                //  color="teal_300_teal_600"
                                className="rounded-md bg-red-400 text-white-A700"
                                onClick={handleCancelOrder}
                              >
                                Cancel Order
                              </Button>
                            </>
                          )}
                        </div>
                      </>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}
