import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { BACKEND_URL } from "config/config";
import { Spinner } from "flowbite-react";
import { API_VERSION, PRICE_MAPPING } from "config/prices";

function OrderSuccess() {
  const { uuid } = useParams(); // Get orderId from the URL
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loadOrder = async () => {
    // Call your API or perform actions with the orderId
    setLoading(true);
    try {
      const response = await axios.get(
        `${BACKEND_URL}/api/${API_VERSION}/order-success/${uuid}?token=${userObj.token}&organizationId=${userObj.organizationId}`
      );
      const payment=response.data
      const plan=PRICE_MAPPING[payment.Data.priceId]
      navigate(`/marketplace/purchase${plan['link']}`, {
        state: { plan, payment },
    });
      setLoading(false)
    } catch (error) {
      console.error("Error fetching searched:", error);
      navigate("/");
    }
  };

  useEffect(() => {
    if (uuid) {
      loadOrder();
    }
  }, [uuid]);

  return (
    <>
      <Helmet>
        <title>Scaledmail - Order Success</title>
        <meta name="description" content="Order Success Page" />
      </Helmet>
      <div className="flex justify-center items-center h-screen w-full">
        {loading ? <Spinner /> : <></>}
      </div>
    </>
  );
}

export default OrderSuccess;
