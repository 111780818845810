import {
  ArrowRightIcon,
  ClipboardIcon,
  EllipsisHorizontalCircleIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { getDomains } from "api/domain";
import { Button, Img, Input, Text } from "components";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import SwapDomainModal from "modals/SwapDomain";
import ProfilePictureModal from "modals/ProfilePicture";
import InstantlyUploadModal from "modals/InstantlyUplaod";
import NameserverHelpModal from "modals/NameserverHelp";
import MultipleSelectInput from "components/MultiSelectInput";
import { DOMAIN_TYPE } from "config/constants";

export default function Domains() {
  // Goto order and goto mailbox fix
  const navigate = useNavigate();

  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [filteredDomains, setFilteredDomains] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null); // Track selected domain (only one)
  const [provider, setProvider] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showPPModal, setShowPPModal] = useState(false); // State for modal visibility
  const [showIUModal, setShowIUModal] = useState(false); // State for modal visibility
  const [showNameserverAskModal, setShowNameserverAskModal] = useState(false); // State for modal visibility
  const [searchTerm, setSearchTerm] = useState("");

  const loadDomains = async () => {
    setLoading(true);
    try {
      const dt = await getDomains(userObj.token, userObj.organizationId, {
        provider,
      });
      setResults(dt);
      setFilteredDomains(dt.domains);
    } catch (error) {
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (results?.domains) {
      const filtered = results.domains.filter((item) =>
        item.Domain?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDomains(filtered);
    }
  }, [searchTerm, results]);

  const handleDomain = async (item) => {
    navigate("/mailboxes", {
      state: { selectedDomain: { value: item.id, label: item.Domain } },
    });
  };

  const handleCheckboxChange = (item) => {
    setSelectedDomain(selectedDomain === item ? null : item);
  };

  const handleNameserversUpdate = () => {
    console.info(selectedDomain);
  };
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard!"); // Optional: Provide feedback to the user
    });
  };

  useEffect(() => {
    loadDomains();
  }, [provider]);

  // Prepare CSV data
  const csvData =
    results?.domains.map((item) => ({
      Domain: item.Domain,
      Mailboxes: item.Mailboxes,
    })) || [];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          {results && (
            <>
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-gray-900">
                  Domains ({results.totalRecords})
                </p>

                <div className="flex items-center space-x-2">
                  {selectedDomain && (
                    <>
                      <div className="md:hidden sm:hidden space-x-2">
                        {/* FIXME: Multiselect and status check  */}
                        {selectedDomain["Nameserver Status"] ===
                          "Nameservers Created" && (
                          <button
                            onClick={handleNameserversUpdate}
                            className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                          >
                            Nameservers Updated
                          </button>
                        )}
                        <button
                          onClick={() => setShowModal(true)}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Swap Domain
                        </button>
                        {/* <button
                          onClick={() => setShowIUModal(true)}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Instantly Upload
                        </button>
                        <button
                          onClick={() => setShowPPModal(true)}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Add Profile Pictures
                        </button> */}
                      </div>
                      <EllipsisHorizontalCircleIcon className="h-7 w-7 !text-gray-500 hover:!text-teal-300 cursor-pointer rotate-90 lg:hidden" />
                    </>
                  )}
                  <button
                    onClick={() => navigate("/marketplace")}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                  >
                    Order Mailboxes
                  </button>
                  <CSVLink
                    data={csvData}
                    filename={"domains.csv"}
                    className="items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm sm:inline-flex hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>

              <div className="flex flex-col mt-2 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="mt-4 flex justify-between items-center gap-2 sm:flex-col">
                      <div className="w-[60%]">
                        <Input
                          placeholder="Search domain"
                          className="w-full" // Ensure the input fills the 60% container
                          prefix={
                            <MagnifyingGlassIcon className="w-5 h-5 mr-5" />
                          }
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      <div className="w-1/2 flex items-start justify-end">
                        <MultipleSelectInput
                          options={DOMAIN_TYPE}
                          label=""
                          placeholder="Select provider"
                          selected={provider}
                          setSelected={setProvider}
                          search={false}
                          className="w-full"
                          multiselect={true}
                        />
                      </div>
                    </div>
                    <div className="h-[75vh] overflow-y-auto">
                      <table className="min-w-full lg:divide-gray-200 divide-y">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Provider
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Domain
                            </th>
                            {/* <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Tags
                            </th> */}
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Mailboxes
                            </th>
                            {/* <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              NameServers
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              NameServers Status
                            </th> */}

                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredDomains &&
                            filteredDomains.map((item, index) => (
                              <tr
                                key={index}
                                className={`bg-white ${
                                  selectedDomain === item ? "bg-gray-100" : ""
                                }`}
                              >
                                <td className="px-4 py-4">
                                  <input
                                    className="rounded-md cursor-pointer"
                                    type="checkbox"
                                    checked={selectedDomain === item}
                                    onChange={() => handleCheckboxChange(item)}
                                  />
                                </td>

                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item["Outlook Order ID"]?.length > 0 ? (
                                    <Img
                                      src="/images/outlook_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : item["Google Order ID"]?.length > 0 ? (
                                    <Img
                                      src="/images/google_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : (
                                    <QuestionMarkCircleIcon className="h-5 w-5" />
                                  )}
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.Domain}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.Mailboxes}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  <p
                                    onClick={
                                      () =>
                                        navigate(
                                          `/billing/${item["Payment ID"][0]}`
                                        )
                                      // navigate(`/billing`,
                                      //    {
                                      //   state: {
                                      //     orderId: item["Payment ID"][0],
                                      //   },
                                      // })
                                    }
                                    className="hover:text-teal-300 cursor-pointer"
                                  >
                                    Go to Order
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  <p
                                    onClick={() => handleDomain(item)}
                                    className="hover:text-teal-300 cursor-pointer"
                                  >
                                    Go to Mailboxes
                                  </p>
                                  {/* <ArrowRightIcon
                                    className="w-4 h-4 hover:text-teal-300 cursor-pointer"
                                    onClick={() => handleDomain(item)}
                                  /> */}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <SwapDomainModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDomain={selectedDomain}
      />
      <ProfilePictureModal
        showModal={showPPModal}
        setShowModal={setShowPPModal}
        selectedDomain={selectedDomain}
      />
      <InstantlyUploadModal
        showModal={showIUModal}
        setShowModal={setShowIUModal}
        selectedDomain={selectedDomain}
      />
      {/* <NameserverHelpModal
        showModal={showNameserverAskModal}
        setShowModal={setShowNameserverAskModal}
      /> */}
    </div>
  );
}

// TODO: MULTISELECT
