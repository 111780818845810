const handleDownloadCSV = (csvData, filename) => {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleDownloadSampleCSV = () => {
  const csvData = `firstname,lastname,alias,domain
Emma,Johnson,emma.johnson,beanstalk.com
Emma,Johnson,emmajohnson,beanstalk.com
Emma,Johnson,emma_johnson,beanstalk.com
Emma,Johnson,emma-johnson,beanstalk.com
Emma,Johnson,ejohnson,beanstalk.com
Emma,Johnson,e.johnson,beanstalk.com
Emma,Johnson,e_johnson,beanstalk.com
Emma,Johnson,e-johnson,beanstalk.com
Emma,Johnson,emma.j,beanstalk.com
Emma,Johnson,emmaj,beanstalk.com
Emma,Johnson,emma_j,beanstalk.com
Emma,Johnson,emma-j,beanstalk.com
Emma,Johnson,johnsonemma,beanstalk.com
Emma,Johnson,johnson.emma,beanstalk.com
Emma,Johnson,johnson_emma,beanstalk.com
Emma,Johnson,johnson-emma,beanstalk.com
Emma,Johnson,johnsone,beanstalk.com
Emma,Johnson,johnson.e,beanstalk.com
Emma,Johnson,johnson-e,beanstalk.com
Emma,Johnson,johnson_e,beanstalk.com
Emma,Johnson,jemma,beanstalk.com
Emma,Johnson,j.emma,beanstalk.com
Emma,Johnson,j_emma,beanstalk.com
Emma,Johnson,j-emma,beanstalk.com
Emma,Johnson,emma.a.johnson,beanstalk.com
Emma,Johnson,e.a.johnson,beanstalk.com
Emma,Johnson,emma.a.j,beanstalk.com
Emma,Johnson,johnson.a.emma,beanstalk.com
Emma,Johnson,johnson.a.e,beanstalk.com
Emma,Johnson,j.a.emma,beanstalk.com
Emma,Johnson,emma.m.johnson,beanstalk.com
Emma,Johnson,e.m.johnson,beanstalk.com
Emma,Johnson,emma.m.j,beanstalk.com
Emma,Johnson,johnson.m.emma,beanstalk.com
Emma,Johnson,johnson.m.e,beanstalk.com
Emma,Johnson,j.m.emma,beanstalk.com
Emma,Johnson,emma.j.johnson,beanstalk.com
Emma,Johnson,e.j.johnson,beanstalk.com
Emma,Johnson,emma.j.j,beanstalk.com
Emma,Johnson,johnson.j.emma,beanstalk.com
Emma,Johnson,johnson.j.e,beanstalk.com
Emma,Johnson,j.j.emma,beanstalk.com
Emma,Johnson,emma.l.johnson,beanstalk.com
Emma,Johnson,e.l.johnson,beanstalk.com
Emma,Johnson,emma.l.j,beanstalk.com
Emma,Johnson,johnson.l.emma,beanstalk.com
Emma,Johnson,johnson.l.e,beanstalk.com
Emma,Johnson,j.l.emma,beanstalk.com
Emma,Johnson,emma.r.johnson,beanstalk.com
Liam,Smith,liam.smith,scaledmail.com
Liam,Smith,liamsmith,scaledmail.com
Liam,Smith,liam_smith,scaledmail.com
Liam,Smith,liam-smith,scaledmail.com
Liam,Smith,lsmith,scaledmail.com
Liam,Smith,l.smith,scaledmail.com
Liam,Smith,l_smith,scaledmail.com
Liam,Smith,l-smith,scaledmail.com
Liam,Smith,liam.s,scaledmail.com
Liam,Smith,liams,scaledmail.com
Liam,Smith,liam_s,scaledmail.com
Liam,Smith,liam-s,scaledmail.com
Liam,Smith,smithliam,scaledmail.com
Liam,Smith,smith.liam,scaledmail.com
Liam,Smith,smith_liam,scaledmail.com
Liam,Smith,smith-liam,scaledmail.com
Liam,Smith,smithl,scaledmail.com
Liam,Smith,smith.l,scaledmail.com
Liam,Smith,smith-l,scaledmail.com
Liam,Smith,smith_l,scaledmail.com
Liam,Smith,sliam,scaledmail.com
Liam,Smith,s.liam,scaledmail.com
Liam,Smith,s_liam,scaledmail.com
Liam,Smith,s-liam,scaledmail.com
Liam,Smith,liam.a.smith,scaledmail.com
Liam,Smith,l.a.smith,scaledmail.com
Liam,Smith,liam.a.s,scaledmail.com
Liam,Smith,smith.a.liam,scaledmail.com
Liam,Smith,smith.a.l,scaledmail.com
Liam,Smith,s.a.liam,scaledmail.com
Liam,Smith,liam.m.smith,scaledmail.com
Liam,Smith,l.m.smith,scaledmail.com
Liam,Smith,liam.m.s,scaledmail.com
Liam,Smith,smith.m.liam,scaledmail.com
Liam,Smith,smith.m.l,scaledmail.com
Liam,Smith,s.m.liam,scaledmail.com
Liam,Smith,liam.j.smith,scaledmail.com
Liam,Smith,l.j.smith,scaledmail.com
Liam,Smith,liam.j.s,scaledmail.com
Liam,Smith,smith.j.liam,scaledmail.com
Liam,Smith,smith.j.l,scaledmail.com
Liam,Smith,s.j.liam,scaledmail.com
Liam,Smith,liam.l.smith,scaledmail.com
Liam,Smith,l.l.smith,scaledmail.com
Liam,Smith,liam.l.s,scaledmail.com
Liam,Smith,smith.l.liam,scaledmail.com
Liam,Smith,smith.l.l,scaledmail.com
Liam,Smith,s.l.liam,scaledmail.com
Liam,Smith,liam.r.smith,scaledmail.com
Olivia,Brown,olivia.brown,b2bleadlists.com
Olivia,Brown,oliviabrown,b2bleadlists.com
Olivia,Brown,olivia_brown,b2bleadlists.com
Olivia,Brown,olivia-brown,b2bleadlists.com
Olivia,Brown,obrown,b2bleadlists.com
Olivia,Brown,o.brown,b2bleadlists.com
Olivia,Brown,o_brown,b2bleadlists.com
Olivia,Brown,o-brown,b2bleadlists.com
Olivia,Brown,olivia.b,b2bleadlists.com
Olivia,Brown,oliviab,b2bleadlists.com
Olivia,Brown,olivia_b,b2bleadlists.com
Olivia,Brown,olivia-b,b2bleadlists.com
Olivia,Brown,brownolivia,b2bleadlists.com
Olivia,Brown,brown.olivia,b2bleadlists.com
Olivia,Brown,brown_olivia,b2bleadlists.com
Olivia,Brown,brown-olivia,b2bleadlists.com
Olivia,Brown,browno,b2bleadlists.com
Olivia,Brown,brown.o,b2bleadlists.com
Olivia,Brown,brown-o,b2bleadlists.com
Olivia,Brown,brown_o,b2bleadlists.com
Olivia,Brown,bolivia,b2bleadlists.com
Olivia,Brown,b.olivia,b2bleadlists.com
Olivia,Brown,b_olivia,b2bleadlists.com
Olivia,Brown,b-olivia,b2bleadlists.com
Olivia,Brown,olivia.a.brown,b2bleadlists.com
Olivia,Brown,o.a.brown,b2bleadlists.com
Olivia,Brown,olivia.a.b,b2bleadlists.com
Olivia,Brown,brown.a.olivia,b2bleadlists.com
Olivia,Brown,brown.a.o,b2bleadlists.com
Olivia,Brown,b.a.olivia,b2bleadlists.com
Olivia,Brown,olivia.m.brown,b2bleadlists.com
Olivia,Brown,o.m.brown,b2bleadlists.com
Olivia,Brown,olivia.m.b,b2bleadlists.com
Olivia,Brown,brown.m.olivia,b2bleadlists.com
Olivia,Brown,brown.m.o,b2bleadlists.com
Olivia,Brown,b.m.olivia,b2bleadlists.com
Olivia,Brown,olivia.j.brown,b2bleadlists.com
Olivia,Brown,o.j.brown,b2bleadlists.com
Olivia,Brown,olivia.j.b,b2bleadlists.com
Olivia,Brown,brown.j.olivia,b2bleadlists.com
Olivia,Brown,brown.j.o,b2bleadlists.com
Olivia,Brown,b.j.olivia,b2bleadlists.com
Olivia,Brown,olivia.l.brown,b2bleadlists.com
Olivia,Brown,o.l.brown,b2bleadlists.com
Olivia,Brown,olivia.l.b,b2bleadlists.com
Olivia,Brown,brown.l.olivia,b2bleadlists.com
Olivia,Brown,brown.l.o,b2bleadlists.com
Olivia,Brown,b.l.olivia,b2bleadlists.com
Olivia,Brown,olivia.r.brown,b2bleadlists.com
Noah,Williams,noah.williams,aestheticlocator.com
Noah,Williams,noahwilliams,aestheticlocator.com
Noah,Williams,noah_williams,aestheticlocator.com
Noah,Williams,noah-williams,aestheticlocator.com
Noah,Williams,nwilliams,aestheticlocator.com
Noah,Williams,n.williams,aestheticlocator.com
Noah,Williams,n_williams,aestheticlocator.com
Noah,Williams,n-williams,aestheticlocator.com
Noah,Williams,noah.w,aestheticlocator.com
Noah,Williams,noahw,aestheticlocator.com
Noah,Williams,noah_w,aestheticlocator.com
Noah,Williams,noah-w,aestheticlocator.com
Noah,Williams,williamsnoah,aestheticlocator.com
Noah,Williams,williams.noah,aestheticlocator.com
Noah,Williams,williams_noah,aestheticlocator.com
Noah,Williams,williams-noah,aestheticlocator.com
Noah,Williams,williamsn,aestheticlocator.com
Noah,Williams,williams.n,aestheticlocator.com
Noah,Williams,williams-n,aestheticlocator.com
Noah,Williams,williams_n,aestheticlocator.com
Noah,Williams,wnoah,aestheticlocator.com
Noah,Williams,w.noah,aestheticlocator.com
Noah,Williams,w_noah,aestheticlocator.com
Noah,Williams,w-noah,aestheticlocator.com
Noah,Williams,noah.a.williams,aestheticlocator.com
Noah,Williams,n.a.williams,aestheticlocator.com
Noah,Williams,noah.a.w,aestheticlocator.com
Noah,Williams,williams.a.noah,aestheticlocator.com
Noah,Williams,williams.a.n,aestheticlocator.com
Noah,Williams,w.a.noah,aestheticlocator.com
Noah,Williams,noah.m.williams,aestheticlocator.com
Noah,Williams,n.m.williams,aestheticlocator.com
Noah,Williams,noah.m.w,aestheticlocator.com
Noah,Williams,williams.m.noah,aestheticlocator.com
Noah,Williams,williams.m.n,aestheticlocator.com
Noah,Williams,w.m.noah,aestheticlocator.com
Noah,Williams,noah.j.williams,aestheticlocator.com
Noah,Williams,n.j.williams,aestheticlocator.com
Noah,Williams,noah.j.w,aestheticlocator.com
Noah,Williams,williams.j.noah,aestheticlocator.com
Noah,Williams,williams.j.n,aestheticlocator.com
Noah,Williams,w.j.noah,aestheticlocator.com
Noah,Williams,noah.l.williams,aestheticlocator.com
Noah,Williams,n.l.williams,aestheticlocator.com
Noah,Williams,noah.l.w,aestheticlocator.com
Noah,Williams,williams.l.noah,aestheticlocator.com
Noah,Williams,williams.l.n,aestheticlocator.com
Noah,Williams,w.l.noah,aestheticlocator.com
Noah,Williams,noah.r.williams,aestheticlocator.com`;
  handleDownloadCSV(csvData, "sample_mailboxes");
};

const handleDownloadDomainSampleCSV = () => {
  const csvData = `domain,redirect
beanstalk.com,
scaledmail.com,
b2bleadlists.com,beanstalk.com
aestheticlocator.com,beanstalk.com
`;

  handleDownloadCSV(csvData, "sample_domains");
};

const getStatusColor = (status) => {
  switch (status) {
    case "complete":
      return "rgb(106 224 224)";
    case "error":
      return "#ff6868";
    case "running":
      return "rgb(144 187 240)";
    default:
      return "gray"; // Default color for unexpected statuses
  }
};

const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";

  // Function to recursively convert nested structures to CSV format
  const processValue = (value) => {
    if (value == null || value == undefined) return "";
    if (Array.isArray(value)) {
      return value.map(processValue).join(" | "); // Join list elements with comma
    } else if (typeof value === "object") {
      return Object.values(value).map(processValue).join(" | "); // Join object values with comma
    } else {
      return value; // No modification for simple values
    }
  };
  const delimeter = ",";
  // Generate header row
  const header = Object.keys(array[0]).map((key) => key.toUpperCase());
  str += header.join(delimeter) + "\r\n";

  // Process each data row
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      const value = array[i][index];
      const new_value = processValue(value);
      console.log(new_value);
      // line += (line !== '' ? delimeter : '') +new_value.replace(/,/g, '|');
    }
    str += line + "\r\n";
  }

  return str;
};

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  // Options for formatting the date
  const dateOptions = { day: "2-digit", month: "short", year: "numeric" };

  // Options for formatting the time
  const timeOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

  // Get the formatted date and time
  const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-GB", timeOptions);

  // Combine date and time
  return `${formattedDate} at ${formattedTime}`;
}

const generateDomains = (qty) => {
  return Array.from({ length: qty }, (_, index) => ({
    // domain: `domain${index + 1}.com`, // Add index to domain
    // redirect_to: `redirect${index + 1}.com`, // Add index to redirect_to
    domain: "",
    redirect_to: "",
    sender_names: [{ first_name: "", last_name: "" }],
  }));
};

module.exports = {
  generateDomains,
  convertToCSV,
  formatTimestamp,
  getStatusColor,
  handleDownloadSampleCSV,
  handleDownloadDomainSampleCSV,
};
