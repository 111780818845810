import { useState } from "react";
import { default as ModalProvider } from "react-modal";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { Spinner } from "flowbite-react";
import { Button, Text } from "components";
import CustomInput from "components/CustomInput";
import CheckoutForm from "components/CheckoutForm";
import { getStripe } from "utils/getStripe";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { SWAP_DOMAIN_PRICE_ID } from "config/prices";
import { isValidDomain } from "utils/validations";

export default function SwapDomainModal({
  showModal,
  setShowModal,
  selectedDomain,
}) {
  const [newDomainName, setNewDomainName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false); // New state to control the display of CheckoutForm

  const handleNewDomainChange = (e) => {
    setNewDomainName(e.target.value);
  };

  const handleSubmit = () => {
    let formattedNewDomain = newDomainName;

    if (!isValidDomain(formattedNewDomain)) {
      return toast.error("Enter valid domain name first");
    }
    if (formattedNewDomain === selectedDomain.Domain) {
      toast.error("Same domain name");
      return;
    }
    setShowCheckout(true);
  };

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-white-A700 rounded-lg max-h-[80%] overflow-y-scroll">
        {/* Domain Input Fields */}
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Swap Domain
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal(false)}
              />
            </div>
            <CustomInput
              label="Current Domain"
              placeholder={selectedDomain?.Domain}
              value={selectedDomain?.Domain}
              className=""
              disabled
            />
            <div className="mt-4">
              <CustomInput
                label="New Domain"
                placeholder="Enter new domain"
                value={newDomainName}
                onChange={handleNewDomainChange}
              />
            </div>
            <div className="flex justify-between w-full gap-4 mt-4">
              {loading ? (
                <Spinner />
              ) : (
                <Button
                  className="tracking-[1.25px] uppercase font-medium min-w-[150px] bg-teal-300 text-white-A700"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
          {showCheckout && (
            <div className="w-1/2 h-[70%] overflow-auto">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  userData={{
                    old_domain_id: selectedDomain.ID,
                    newDomain: newDomainName,
                    priceId: SWAP_DOMAIN_PRICE_ID,
                  }}
                  return_url={`${window.location.origin}/billing`}
                />
              </Elements>
            </div>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
