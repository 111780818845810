import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getPendingOrder = async (token, organizationId, id) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${API_VERSION}/order/${id}?token=${token}&organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching payments:", error);
    throw error;
  }
};

export { getPendingOrder };
