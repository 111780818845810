import React from "react";

const Toggle = ({ label, value, onChange,className="" }) => {
  return (
    <div className={`flex  gap-4 items-center ${className}`}>
      <label className="text-sm font-bold text-gray-900">{label}</label>
      <div className="flex p-1.5 bg-white border border-gray-200 rounded-lg shadow">
        {/* Yes Button */}
        <button
          type="button"
          className={`ml-0.5 relative border border-transparent rounded-lg py-1 text-sm font-medium text-gray-700 whitespace-nowrap px-3 ${
            value ? "bg-teal-300 text-white" : ""
          }`}
          onClick={() => onChange(true)}
        >
          Yes
        </button>

        {/* No Button */}
        <button
          type="button"
          className={`relative py-1 text-sm font-medium rounded-lg shadow-sm whitespace-nowrap px-3 ${
            !value ? "bg-teal-300 text-white" : ""
          }`}
          onClick={() => onChange(false)}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default Toggle;
